import z from "zod"

import type { SupportedWorkspaceLanguageCode } from "../../domain/Workspace.entity"

export const generateBriefResponse = z.object({
	id: z.string(),
})

export type GenerateBriefResponse = z.infer<typeof generateBriefResponse>

export type BriefAttendee = {
	firstName: string
	lastName: string
	email: string
	isOrganizer: boolean
	isYou: boolean
}

export type MeetingType = "sales-demo" | "internal-meeting" | "other" | "sales-follow-up" | "sales-discovery"

export type GenerateBriefParams = {
	meetingTitle: string
	meetingType: MeetingType | null
	attendees: BriefAttendee[]
	email: string
	language: SupportedWorkspaceLanguageCode | null
}

export type BriefCompletedTaskResult = {
	claimUrl: string | null
}

export type IPublicBriefGateway = {
	generateBrief: (params: GenerateBriefParams) => Promise<z.infer<typeof generateBriefResponse>>
}

export class BriefRequestError extends Error {
	constructor(
		message: string,
		public readonly code: number,
	) {
		super(message)
		this.name = "BriefRequestError"
	}
}
