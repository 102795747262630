import { assertNever } from "assert-never"

import {
	GetCallWithRelationshipSchema,
	type ICallCreateCommand,
	type ICallListQuery,
	type ICallsGateway,
} from "../../application/gateways/calls.gateway"
import { Call, type CallObjection } from "../../domain/Call.entity"
import { PublicCall } from "../../domain/PublicCall.entity"
import { ensureNotAnApiError } from "./api-errors"

const _mockedObjections: CallObjection[] = [
	{
		title: "Data privacy concerns",
		extracts: [
			{
				startsAtMs: 50 * 1000,
				endsAtMs: 60 * 1000,
				quote: "That's exactly right.",
			},
			{
				startsAtMs: 50 * 1000,
				endsAtMs: 60 * 1000,
				quote: "That's exactly right.",
			},
		],
		description:
			"The prospect expressed concerns about data protection in relation to product integration, particularly regarding data-sharing with third parties.",
		recommendations: [
			"Forward your privacy policy to reinforce your explanations.",
			"Offer a data processing agreement to address data protection concerns.",
		],
		responseAnalysis:
			"The prospect is concerned about data privacy and data protection. They are particularly interested in data-sharing with third parties.",
		riskLevel: "high",
	},
	{
		title: "Budget constraints",
		extracts: [
			{
				startsAtMs: 120 * 1000,
				endsAtMs: 130 * 1000,
				quote: "That's exactly right.",
			},
		],
		description: "The prospect expressed concerns about budget constraints and the cost of the proposed solution.",
		recommendations: [
			"Offer a discount or a payment plan to make the solution more affordable.",
			"Explain the ROI of the proposed solution to justify the cost.",
		],
		responseAnalysis:
			"The prospect is concerned about budget constraints and the cost of the proposed solution. They are looking for a more affordable solution.",
		riskLevel: "medium",
	},
	{
		title: "Integration complexity",
		extracts: [
			{
				startsAtMs: 180 * 1000,
				endsAtMs: 190 * 1000,
				quote: "That's exactly right.",
			},
		],
		description:
			"The prospect expressed concerns about the complexity of integrating the proposed solution with their existing systems.",
		recommendations: [
			"Offer a dedicated support team to assist with the integration process.",
			"Provide a detailed integration plan to address the prospect's concerns.",
		],
		responseAnalysis:
			"The prospect is concerned about the complexity of integrating the proposed solution with their existing systems. They are looking for support and guidance to ensure a smooth integration process.",
		riskLevel: "low",
	},
]

export class HttpCallsGateway implements ICallsGateway {
	constructor(private readonly baseApiUrl: string) {}

	public async getWithRelationship(callId: string) {
		const endpointUrl = `${this.baseApiUrl}/calls/${callId}/with-relationship`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()
		ensureNotAnApiError(json)

		const parsed = GetCallWithRelationshipSchema.parse(json)

		return {
			call: Call.fromApiCall(parsed.call, this.baseApiUrl),
			relationship: parsed.relationship,
		}
	}

	public async list(query: ICallListQuery): Promise<{ calls: Call[]; totalMatchingCalls: number }> {
		const queryParams = new URLSearchParams()

		if (query.pagination) {
			queryParams.append("pageSize", query.pagination.pageSize.toString())
			queryParams.append("pageNumber", query.pagination.pageNumber.toString())
		}

		switch (query.filter.type) {
			case "any":
				// leave undefined
				break

			case "unassigned":
				queryParams.append("filter", "unassigned")
				break

			case "assigned":
				queryParams.append("assignedUserIds", JSON.stringify(query.filter.userIds))
				break

			default:
				assertNever(query.filter)
		}

		if (query.search) {
			queryParams.append("search", query.search)
		}

		if (query.tagIds) {
			queryParams.append("tagIds", JSON.stringify(query.tagIds))
		}

		const endpointUrl = `${this.baseApiUrl}/calls?${queryParams}`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()

		if (!Array.isArray(json.calls) || typeof json.totalMatchingCalls !== "number") {
			throw new Error("Invalid response")
		}
		const calls: Call[] = json.calls.map((apiCall: unknown): Call => Call.fromApiCall(apiCall, this.baseApiUrl))
		return {
			calls,
			totalMatchingCalls: json.totalMatchingCalls,
		}
	}

	public async transcribe(call: Call): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/calls/${call.props.id}/transcribe`

		await fetch(endpointUrl, {
			method: "POST",
			body: null,
			credentials: "include",
		})
	}

	public async analyze(call: Call): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/calls/${call.props.id}/analyze`

		await fetch(endpointUrl, {
			method: "POST",
			body: null,
			credentials: "include",
		})
	}

	public async get(callId: string, inviteToken?: string): Promise<Call> {
		const searchParams = inviteToken ? `?inviteToken=${encodeURIComponent(inviteToken)}` : ""
		const endpointUrl = `${this.baseApiUrl}/calls/${callId}${searchParams}`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()
		ensureNotAnApiError(json)

		return Call.fromApiCall(json, this.baseApiUrl)
	}

	public async getByPublicAccessToken(publicAccessToken: string): Promise<PublicCall> {
		const endpointUrl = `${this.baseApiUrl}/share/calls/${publicAccessToken}`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()
		ensureNotAnApiError(json)

		return PublicCall.fromApiCall(json)
	}

	public async delete(call: Call) {
		const endpointUrl = `${this.baseApiUrl}/calls/${call.props.id}`
		await fetch(endpointUrl, {
			method: "DELETE",
			credentials: "include",
		})
	}

	public async create(command: ICallCreateCommand): Promise<Call> {
		const endpointUrl = `${this.baseApiUrl}/calls/create`
		const formData = new FormData()
		formData.append("file", command.audioFile)
		formData.append("name", command.name)
		formData.append("language", command.language)
		if (command.agentId) {
			formData.append("agentId", command.agentId)
		}
		if (command.durationSec) {
			formData.append("durationSec", command.durationSec.toString())
		}
		if (command.transcription) {
			formData.append("transcription", command.transcription)
		}
		const res = await fetch(endpointUrl, {
			body: formData,
			method: "POST",
			credentials: "include",
		})
		const json = await res.json()
		return Call.fromApiCall(json, this.baseApiUrl)
	}

	public async setTags(call: Call, tagIds: string[]) {
		const endpointUrl = `${this.baseApiUrl}/calls/${call.props.id}/tags`
		await fetch(endpointUrl, {
			method: "POST",
			body: JSON.stringify({
				tagIds,
			}),
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})
	}
}
