import clsx from "clsx"
import React from "react"

import type { CallHighlight, Library, LibraryFolder } from "../../../../core/domain/Library.entity"
import type { CoachingSession } from "../../../../core/infra/gateways/http.library.gateway/getCoachingSessionsResponse.schema"
import { Card } from "../../../components/design-system/Card.component"
import { SpinnerIcon } from "../../../components/design-system/SpinnerIcon.component"
import { useLanguage } from "../../../contexts/language.context"
import { makeDemoCallHighlight } from "../../../utils/onboardingDemo/demoLibrary"
import { DateProvider } from "../../../utils/time"
import { COACHING_SESSIONS_FOLDER_ID } from "./CoachingSession.util"
import { CoachingSessionsView } from "./CurrentLibraryFolder/CoachingSessionsView.component"
import { CurrentLibraryFolder } from "./CurrentLibraryFolder/CurrentLibraryFolder.component"
import { CurrentLibraryFolderChildrenGrid } from "./CurrentLibraryFolder/CurrentLibraryFolderChildrenGrid.component"
import { LibraryFoldersBreadcrumb } from "./LibraryFoldersBreadcrumb.component"
import type { CallHighlightActions } from "./types"

export type MainPanelProps = {
	library: Library | "loading"
	highlights: CallHighlight[] | "loading" | "no-folder-selected"
	newHighlightsAreLoading?: boolean
	coachingSessions: CoachingSession[] | "loading"
	currentFolder?: LibraryFolder
	currentHighlightId?: string
	highlightActions: CallHighlightActions
	disabled: boolean
}

export function MainPanel({
	highlights,
	newHighlightsAreLoading,
	currentFolder,
	currentHighlightId,
	library,
	coachingSessions,
	highlightActions,
	disabled,
}: MainPanelProps) {
	const { t } = useLanguage()

	const demoHighlights = React.useMemo(() => [makeDemoCallHighlight(new DateProvider(), t)], [t])

	const isCoachingSessions = currentFolder?.id === COACHING_SESSIONS_FOLDER_ID
	const isCoachingSessionsLoading = isCoachingSessions && coachingSessions === "loading"

	if (highlights === "loading" || library === "loading" || isCoachingSessionsLoading) {
		return (
			<Card title={t("Library")} className="m-6">
				<div className="flex items-center justify-center h-full">
					<SpinnerIcon className="w-10 h-10 text-gray-400 animate-spin" />
				</div>
			</Card>
		)
	}

	const shouldDisplayDemoHighlight = (() => {
		return !currentFolder && library.getTotalCallHighlightCount() === 0
	})()
	const highlightsToDisplay = shouldDisplayDemoHighlight ? demoHighlights : highlights
	return (
		<div className={clsx("flex-1 min-w-0 bg-gray-100", disabled && "pointer-events-none")}>
			<div className="m-6 space-y-4">
				<LibraryFoldersBreadcrumb currentFolder={currentFolder} library={library} />
				<CurrentLibraryFolderChildrenGrid
					currentFolderChildren={currentFolder?.children ?? [library.userRoot, library.workspaceRoot]}
				/>
				{isCoachingSessions && coachingSessions !== "loading" && (
					<CoachingSessionsView coachingSessions={coachingSessions} />
				)}
				{highlightsToDisplay !== "no-folder-selected" && currentFolder?.id !== COACHING_SESSIONS_FOLDER_ID && (
					<CurrentLibraryFolder
						currentFolder={currentFolder}
						currentHighlightId={currentHighlightId}
						highlights={highlightsToDisplay}
						highlightActions={highlightActions}
						newHighlightsAreLoading={newHighlightsAreLoading}
					/>
				)}
			</div>
		</div>
	)
}
