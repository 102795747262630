import type { Team } from "../../../../../core/domain/Team.entity"
import { useLanguage } from "../../../../contexts/language.context"

type TeamsDropdownProps = {
	teams: Array<{ team: Team; enabled: boolean }>
	allUsersEnabled: boolean // New prop to track if "All Users" is enabled
	onChange: (teamId: string, enabled: boolean) => void
	onAllUsersChange: (enabled: boolean) => void // New callback for "All Users"
}

export function TeamsDropdown({ teams, allUsersEnabled, onChange, onAllUsersChange }: TeamsDropdownProps) {
	const { t } = useLanguage()

	return (
		<div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
			{/* All Users item */}
			<label
				htmlFor="all-users"
				className="relative flex items-start py-2 border-b border-gray-200 cursor-pointer"
			>
				<div className="min-w-0 flex-1 text-sm/6">
					<span className="select-none font-medium text-gray-900">{t("All Users")}</span>
				</div>
				<div className="ml-3 flex h-6 items-center">
					<input
						id="all-users"
						name="all-users"
						type="checkbox"
						className="size-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
						checked={allUsersEnabled}
						onChange={(e) => onAllUsersChange(e.target.checked)}
					/>
				</div>
			</label>

			{/* Individual team items */}
			{teams.map(({ team, enabled }, teamIndex) => (
				<label
					key={teamIndex}
					htmlFor={`team-${team.id}`}
					className="relative flex items-start py-2 cursor-pointer"
				>
					<div className="min-w-0 flex-1 text-sm/6">
						<span className="select-none font-medium text-gray-900">{team.name}</span>
					</div>
					<div className="ml-3 flex h-6 items-center">
						<input
							id={`team-${team.id}`}
							name={`team-${team.id}`}
							type="checkbox"
							className="size-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
							checked={enabled}
							onChange={(e) => {
								onChange(team.id, e.target.checked)
							}}
						/>
					</div>
				</label>
			))}
		</div>
	)
}
