import type { IChatSessionGateway } from "../../application/gateways/chat-session.gateway"
import { ChatPropertiesSchema, ChatSession } from "../../domain/ChatSession.entity"

export class HttpChatSessionGateway implements IChatSessionGateway {
	constructor(private readonly baseApiUrl: string) {}

	public async getById(callId: string) {
		const endpointUrl = `${this.baseApiUrl}/chat/${callId}/get-session`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()
		const properties = ChatPropertiesSchema.parse(json)
		return ChatSession.fromProperties(properties)
	}

	public async getByCallId(callId: string) {
		const endpointUrl = `${this.baseApiUrl}/chat/calls/${callId}/get-session`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()
		const properties = ChatPropertiesSchema.parse(json)
		return ChatSession.fromProperties(properties)
	}

	public async createCallSession(callId: string) {
		const endpointUrl = `${this.baseApiUrl}/chat/create-session`
		const res = await fetch(endpointUrl, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify({ callId, type: "call" }),
			headers: {
				"Content-Type": "application/json",
			},
		})
		const json = await res.json()
		const properties = ChatPropertiesSchema.parse(json)
		return ChatSession.fromProperties(properties)
	}

	public async sendMessage(chatSession: ChatSession, message: string): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/chat/send-message`
		const res = await fetch(endpointUrl, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ message, sessionId: chatSession.properties.id }),
		})
		if (!res.ok) {
			throw new Error("Failed to send message")
		}
	}
}
