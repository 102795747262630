import {
	BriefRequestError,
	type GenerateBriefParams,
	type GenerateBriefResponse,
	generateBriefResponse,
	type IPublicBriefGateway,
} from "../../application/gateways/public-brief.gateway"

export class HttpPublicBriefGateway implements IPublicBriefGateway {
	constructor(private readonly baseApiUrl: string) {}

	async generateBrief(params: GenerateBriefParams): Promise<GenerateBriefResponse> {
		const res = await fetch(`${this.baseApiUrl}/brief`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(params),
		})

		if (!res.ok) {
			throw new BriefRequestError(await res.text(), res.status)
		}

		const json = await res.json()
		return generateBriefResponse.parse(json)
	}
}
