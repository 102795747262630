import type { ToastOptions } from "react-toastify"

export const RIPPLETIDE_WORKSPACE_ID = "workspace_1"
export const RIPPLETIDE_DEMO_WORKSPACE_ID = "workspace_2"
export const INTESCIA_WORKSPACE_ID = "workspace_4"
export const EXPLORE_WORKSPACE_ID = "workspace_b48592f3-5234-4f11-b755-f0bf042029ef"
export const NEW_TRAINING_PROGRAM_ID = "new"

export const DEMO_TRAINING_PROGRAM_ID = "tp_212f5abe-fa86-4620-a315-7db54f1b96e2" // "Rippletide onboarding" training program

export const baseToastConfig: ToastOptions = {
	position: "bottom-right",
	autoClose: 3000,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	theme: "light",
}

export function ENABLE_COACHING_SESSIONS_FOR_WORKSPACE(_workspaceId: string) {
	return null // return workspaceId === RIPPLETIDE_WORKSPACE_ID
}

export const HELP_CENTER_URLS = {
	Library: {
		CreateCallHighlights: {
			// TODO: translate it-es-nl-pt links
			en: "https://help.rippletide.com/en/article/how-to-create-call-highlights-in-your-library-oh6agh/",
			it: "https://help.rippletide.com/en/article/how-to-create-call-highlights-in-your-library-oh6agh/",
			es: "https://help.rippletide.com/en/article/how-to-create-call-highlights-in-your-library-oh6agh/",
			fr: "https://help.rippletide.com/fr/article/comment-creer-des-extraits-dans-votre-bibliotheque-gl4vqx/",
			nl: "https://help.rippletide.com/en/article/how-to-create-call-highlights-in-your-library-oh6agh/",
			pt: "https://help.rippletide.com/en/article/how-to-create-call-highlights-in-your-library-oh6agh/",
		},
	},
	Settings: {
		Language: {
			// TODO: translate it-es-nl-pt links
			en: "https://help.rippletide.com/en/article/rippletide-language-settings-773om2/",
			it: "https://help.rippletide.com/en/article/rippletide-language-settings-773om2/",
			es: "https://help.rippletide.com/en/article/rippletide-language-settings-773om2/",
			fr: "https://help.rippletide.com/fr/article/parametres-de-langue-de-rippletide-oysm4z/",
			nl: "https://help.rippletide.com/en/article/rippletide-language-settings-773om2/",
			pt: "https://help.rippletide.com/en/article/rippletide-language-settings-773om2/",
		},
	},
}

export type Config = {
	env: "production" | "development"
	apiUrl: string
	mixpanel: {
		token: string
	}
}

function getProductionApiUrl() {
	const isPreview = window.location.hostname.endsWith("rippletide.vercel.app")
	if (isPreview) {
		// relies on vercel's preview deployment naming convention
		// see https://vercel.com/docs/edge-network/rewrites
		// see vercel.json
		return `https://${window.location.hostname}/api`
	}

	if (window.location.hostname.includes("rippletide.com")) {
		return "https://api.rippletide.com"
	}

	return "https://api.beasight.com"
}

const productionConfig: Config = {
	env: "production",
	apiUrl: getProductionApiUrl(),
	mixpanel: {
		token: "d70e4b7452f210206b9f0d916dc8c055",
	},
}

const developmentConfig: Config = {
	env: "development",
	apiUrl: "http://localhost:3000",
	mixpanel: {
		token: "9cbe9e4c10673e541399d8b52556eb92",
	},
}

const config = process.env.NODE_ENV === "development" ? developmentConfig : productionConfig

export default config
