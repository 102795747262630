import { ArrowRightOnRectangleIcon } from "@heroicons/react/20/solid"
import {
	CheckIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	ClockIcon,
	UserIcon,
	XMarkIcon,
} from "@heroicons/react/24/outline"
import clsx from "clsx"
import { useCallback, useState } from "react"
import { Navigate, useParams } from "react-router-dom"
import { useMount } from "react-use"

import type { Relationship } from "../../../core/application/gateways/relationships.gateway"
import { type Call } from "../../../core/domain/Call.entity"
import type { CallExcerpt } from "../../../core/domain/CallExcerpt.entity"
import type { CallHighlight } from "../../../core/domain/Library.entity"
import type { User } from "../../../core/domain/User.entity"
import { ApiError, errorMessageTranslationKeyByApiErrorCode } from "../../../core/infra/gateways/api-errors"
import { Alert } from "../../components/design-system/Alert.component"
import { Badge } from "../../components/design-system/Badge.component"
import { Modal } from "../../components/design-system/Modal.component"
import { SpinnerIcon } from "../../components/design-system/SpinnerIcon.component"
import { TrackingButton } from "../../components/design-system/TrackingButton.component"
import { TrackingExternalHref } from "../../components/design-system/TrackingExternalHref.component"
import { BriefRenderer } from "../../components/shared/BriefRenderer.component"
import { CallTagList } from "../../components/shared/CallTagList.component"
import { CallChatWidget } from "../../components/shared/Chat/CallChatWidget.component"
import { Layout } from "../../components/shared/Layout/Layout.component"
import { PublicLayout } from "../../components/shared/PublicLayout/PublicLayout.component"
import { RelationshipPanelContent } from "../../components/shared/RelationshipSidePanel/RelationshipPanelContent.component"
import { ScoreBadge } from "../../components/shared/ScoreBadge.component"
import { useSession } from "../../contexts/authentication.context"
import { CallVideoPlayerProvider } from "../../contexts/callVideoPlayer.context"
import { useDependencies } from "../../contexts/dependencies.context"
import { type TFunction, useLanguage } from "../../contexts/language.context"
import { useWorkspaceUsers } from "../../contexts/workspace-users.context"
import { useAvailableCallTags } from "../../hooks/useAvailableCallTags"
import { useCall } from "../../hooks/useCall"
import { useCallFeaturesVisibility } from "../../hooks/useCallFeaturesVisibility"
import { makeDashboardPath, makeRecentCallsPath } from "../../router/Router"
import { makeDemoCallHighlight } from "../../utils/onboardingDemo/demoLibrary"
import { DateProvider } from "../../utils/time"
import { Call_MainPanel } from "./components/_MainPanel/_MainPanel.component"
import { ShareCallButton } from "./components/ShareCallButton"
import { _SidePanel } from "./components/SidePanel/_SidePanel.component"

export function CallPage() {
	const { callId = "should_never_happen" } = useParams<{ callId: string }>()
	const { user } = useSession()
	const { call, relationship, isLoading, error, refetchCall } = useCall(callId, {
		withRelationship: true,
	})
	const { t } = useLanguage()

	const isWorkspaceManager = user?.isWorkspaceManager()
	if (!call) {
		if (error) {
			if (error instanceof ApiError && error?.code === "MissingAuthTokenError") {
				return <Navigate to="/login" replace={true} />
			}
			return (
				<Layout
					pageName={`Call-${error.code}`}
					isFluid
					BackToLink={
						<Layout.BackToLink
							to={isWorkspaceManager ? makeRecentCallsPath() : makeDashboardPath()}
							label={isWorkspaceManager ? t("Back to call list") : t("Back to dashboard")}
							eventName={isWorkspaceManager ? "Back to call list" : "Back to dashboard"}
						/>
					}
				>
					<Alert title="Error" description={errorMessageTranslationKeyByApiErrorCode[error.code]} />
				</Layout>
			)
		}
		if (!isLoading) {
			return <Navigate to="/dashboard" replace={true} />
		}

		return (
			<Layout pageName={t("Loading…")} isFluid className="flex items-center justify-center">
				<SpinnerIcon className="h-8 max-h-8 max-w-8 w-8 mr-2 flex-initial" />
			</Layout>
		)
	}

	return <_CallPageContent call={call} relationship={relationship} refetchCall={refetchCall} />
}

function useCallHighlights(call: Call, user: User | undefined) {
	const { libraryFoldersGateway } = useDependencies()
	const [callHighlights, setCallHighlights] = useState<CallHighlight[] | "loading">(user ? "loading" : [])
	const { t } = useLanguage()

	const refreshHighlights = useCallback(async () => {
		if (!user) return
		if (call.isDemoCall()) {
			setCallHighlights([makeDemoCallHighlight(new DateProvider(), t)])
			return
		}

		setCallHighlights(await libraryFoldersGateway.getCallHighlights(call))
	}, [call, libraryFoldersGateway, t, user])

	useMount(refreshHighlights)

	return { callHighlights, refreshHighlights }
}

function useCallExcerpts(call: Call, user: User | undefined) {
	const { callExcerptsGateway } = useDependencies()
	const [callExcerpts, setCallExcerpts] = useState<CallExcerpt[] | "loading">(user ? "loading" : [])

	const refreshExcerpts = useCallback(async () => {
		if (!user) return
		setCallExcerpts(await callExcerptsGateway.getByCall(call.props.id))
	}, [call, callExcerptsGateway, user])

	useMount(refreshExcerpts)

	return { callExcerpts, refreshExcerpts }
}

function useCallBriefingModal() {
	const [isBriefingModalOpen, setIsBriefingModalOpen] = useState(false)
	const handleCloseBriefingModal = useCallback(() => setIsBriefingModalOpen(false), [])
	const handleOpenBriefingModal = useCallback(() => setIsBriefingModalOpen(true), [])

	return { isBriefingModalOpen, handleCloseBriefingModal, handleOpenBriefingModal }
}

export function _CallPageContent({
	call,
	relationship,
	refetchCall,
}: {
	call: Call
	relationship: Relationship | undefined
	refetchCall: () => Promise<void>
}) {
	const { t } = useLanguage()
	const { isBriefingModalOpen, handleCloseBriefingModal, handleOpenBriefingModal } = useCallBriefingModal()
	const { availableTags } = useAvailableCallTags()
	/**
	 * This page can be accessed:
	 *   - by an authenticated user
	 *   - by an unauthenticated user, if there is its invite token in the URL
	 *
	 * In this case, the user will be undefined and the page simplified
	 * Note that both a workspace and a user entity exist in the backend, they're just not presented to the frontend yet
	 */
	const { user, workspace } = useSession()
	const { callHighlights, refreshHighlights } = useCallHighlights(call, user)
	const { callExcerpts, refreshExcerpts } = useCallExcerpts(call, user)

	const handleRefreshHighlights = useCallback(async () => {
		await Promise.all([refreshHighlights(), refreshExcerpts()])
	}, [refreshHighlights, refreshExcerpts])

	const handleRefetchCall = useCallback(async () => {
		await refetchCall()
		await handleRefreshHighlights()
	}, [refetchCall, handleRefreshHighlights])

	const [isRelationshipPanelCollapsed, setIsRelationshipPanelCollasped] = useState(false)

	const durationLabel = call.getFormattedDuration()
	const recordedOn = getFormattedRecordedOnDate(call, t)
	const callFeaturesVisibility = useCallFeaturesVisibility(call)

	const LayoutComponent = user ? Layout : PublicLayout
	const isPublic = !user

	const crmSyncStatus = call.getCrmSyncStatus(t, workspace, !user, Boolean(user?.isWorkspaceOwner()))

	const callDetailsComponent = (
		<>
			{isPublic && <AssignedUserIcon call={call} />}
			<Layout.PageMetaItem>{recordedOn}</Layout.PageMetaItem>
			{durationLabel && <Layout.PageMetaItem label={durationLabel} Icon={ClockIcon} />}
			{call.props.countryCode && (
				<Layout.PageMetaItem>
					<img
						src={`https://flagsapi.com/${call.props.countryCode}/flat/64.png`}
						alt={call.props.countryCode}
						className="mr-1.5 h-6 w-6 flex-shrink-0 text-gray-400"
					/>
				</Layout.PageMetaItem>
			)}
			{callFeaturesVisibility.scoring && !workspace?.props.preferences?.hideMeddicScoring && call.props.score && (
				<Layout.PageMetaItem>
					<ScoreBadge score={call.props.score.totalScore} maxScore={call.props.score.totalMaxScore} />
				</Layout.PageMetaItem>
			)}
			{crmSyncStatus?.status && (crmSyncStatus.status === "success" || crmSyncStatus.status === "error") && (
				<Layout.PageMetaItem>
					<div title={crmSyncStatus.label} className="cursor-default">
						{crmSyncStatus.linkTo ? (
							<TrackingExternalHref href={crmSyncStatus.linkTo} eventName="Call: crm sync badge clicked">
								{crmSyncStatus.status === "error" && <XMarkIcon className="h-5 w-5 text-red-500" />}
								{crmSyncStatus.status === "success" && <CheckIcon className="h-5 w-5 text-green-500" />}
								<Badge label={t("CRM sync")} variant={crmSyncStatus.status} />
							</TrackingExternalHref>
						) : (
							<Badge label={t("CRM sync")} variant={crmSyncStatus.status} />
						)}
					</div>
				</Layout.PageMetaItem>
			)}
		</>
	)

	return (
		<CallVideoPlayerProvider call={call}>
			<CallChatWidget call={call} />

			{call.props.briefing && (
				<Modal open={isBriefingModalOpen} onClose={handleCloseBriefingModal}>
					<BriefRenderer briefing={call.props.briefing} />
					<div className="flex justify-end mt-4">
						<TrackingButton
							eventName="Mark briefing as read clicked"
							className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
							onClick={handleCloseBriefingModal}
						>
							<span className="text-s text-gray-500">{t("Mark as read")}</span>
						</TrackingButton>
					</div>
				</Modal>
			)}
			<LayoutComponent
				pageName="Call"
				isFluid
				PageTitle={isPublic ? <Layout.PageTitle title={call.props.name} /> : undefined}
				PageMeta={user ? undefined : <Layout.PageMeta>{callDetailsComponent}</Layout.PageMeta>}
				isSignupAllowed
				className={clsx(
					!isPublic && "bg-gray-100 !pr-4 !m-0 flex-1 !pt-0",
					relationship ? "!pl-0 !pb-0" : "!pl-4",
				)}
			>
				{call.props.unprocessableReason && (
					<div className="my-8">
						<Alert
							title={call.getUnprocessableReasonString(t)}
							description={t("This call will not be processed by our services.")}
						/>
					</div>
				)}
				<div className={clsx("flex flex-col-reverse md:flex-row md:space-x-6 flex-1", isPublic && "mt-2")}>
					{relationship && (
						<div
							className={clsx(
								"shadow-sm bg-white border border-gray-200 py-2 pl-6 max-h-[calc(100vh-64px)] pt-6 relative transition-all duration-300",
								isRelationshipPanelCollapsed ? "w-0 !p-0 ml-4" : "flex-1",
							)}
						>
							<>
								<TrackingButton
									eventName="Call page: relationship panel close button clicked"
									className="absolute top-16 -right-3 bg-white border border-gray-300 rounded-md hover:bg-gray-100"
								>
									{isRelationshipPanelCollapsed ? (
										<ChevronRightIcon
											className="h-6 w-6 text-gray-500 cursor-pointer"
											onClick={() => setIsRelationshipPanelCollasped(false)}
										/>
									) : (
										<ChevronLeftIcon
											className="h-6 w-6 text-gray-500 cursor-pointer"
											onClick={() => setIsRelationshipPanelCollasped(true)}
										/>
									)}
								</TrackingButton>
								<div
									className={clsx(
										isRelationshipPanelCollapsed && "overflow-hidden",
										"max-h-full overflow-y-auto overflow-x-hidden",
									)}
								>
									<RelationshipPanelContent
										relationship={relationship}
										currentCall={call}
										shouldOpenTimelineCallInNewTab={false}
										originPage="call"
										className="pr-6"
									/>
								</div>
							</>
						</div>
					)}

					<div className="mt-4 flex-[2] flex gap-6">
						<div className="flex-1 flex flex-col gap-4">
							{!isPublic && (
								<div className="bg-white rounded-lg shadow-sm p-4">
									<div className="mb-2">
										<div className="flex items-center justify-between">
											<div className="flex gap-2">
												<h2
													title={call.props.name}
													className={clsx(
														"text-md font-bold leading-7 text-navy-500 sm:truncate sm:tracking-tight",
														relationship ? "sm:text-xl max-w-[40vw]" : "sm:text-2xl",
													)}
												>
													{call.props.name}
												</h2>
											</div>

											<div className="my-2 flex items-center gap-2">
												{call.props.briefing && (
													<TrackingButton
														onClick={handleOpenBriefingModal}
														eventName="Call page: Briefing clicked"
														className="flex items-center rounded bg-emerald-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
													>
														<span className="flex items-center gap-1">
															{t("View brief")}
															<ArrowRightOnRectangleIcon className="w-3 h-3" />
														</span>
													</TrackingButton>
												)}
												<ShareCallButton call={call} />
											</div>
										</div>

										<div className="flex">
											<CallTagList
												call={call}
												availableTags={availableTags}
												onSaveSuccess={handleRefetchCall}
											/>
										</div>

										<div className={clsx("flex gap-4", !relationship && "mb-4")}>
											<AssignedUserIcon call={call} />

											{callDetailsComponent}
										</div>
									</div>
								</div>
							)}
							<div className="flex md:flex-row gap-6 flex-col">
								<div className="md:w-6/12 flex-1">
									<Call_MainPanel
										call={call}
										highlights={callHighlights}
										refreshHighlights={handleRefreshHighlights}
										timeline={true}
										timelineVerticalSpacing={2}
										className="bg-white"
										excerpts={callExcerpts}
									/>
								</div>

								<_SidePanel call={call} />
							</div>
						</div>
					</div>
				</div>
			</LayoutComponent>
		</CallVideoPlayerProvider>
	)
}

function AssignedUserIcon({ call }: { call: Call }) {
	const { user } = useSession()
	if (!user) return null

	if (user.isWorkspaceManager()) {
		return <AnyWorkspaceUserIcon call={call} />
	}

	return <CurrentUserIcon user={user} />
}

function AnyWorkspaceUserIcon({ call }: { call: Call }) {
	const { t } = useLanguage()
	const { workspaceUsers } = useWorkspaceUsers()

	const assignedUser = workspaceUsers?.find((user) => user.id === call.props.assignedUserId)
	// TODO: clicking on this icon should go the the overview page filtered by this user
	return (
		<Layout.PageMetaItem
			Icon={UserIcon}
			label={call.isDemoCall() ? "Rippletide" : assignedUser?.getFullName() ?? t("Unassigned")}
		/>
	)
}

function CurrentUserIcon({ user }: { user: User }) {
	return <Layout.PageMetaItem Icon={UserIcon} label={user.getFullName()} />
}

function getFormattedRecordedOnDate(call: Call, t: TFunction) {
	const createdToday = new DateProvider().isToday(call.props.createdAt)

	if (createdToday) {
		return t("Recorded today at {{time}}", {
			time: call.getCreatedAtDayAndTime(t).time,
		})
	}

	return t("Recorded on {{date}} at {{time}}", {
		date: call.getCreatedAtDayAndTime(t).day,
		time: call.getCreatedAtDayAndTime(t).time,
	})
}
