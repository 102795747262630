import type { GetTaskResultResponse } from "../../application/gateways/tasks.gateway"
import { getTaskResultResponse, type ITasksGateway } from "../../application/gateways/tasks.gateway"

export class HttpTasksGateway implements ITasksGateway {
	constructor(private readonly baseApiUrl: string) {}

	async getTaskResult<TResult>(taskId: string): Promise<GetTaskResultResponse<TResult>> {
		const res = await fetch(`${this.baseApiUrl}/tasks/${taskId}`, {
			method: "GET",
		})

		if (!res.ok) {
			throw new Error(`Failed to fetch task result: ${res.statusText}`)
		}

		const data = await res.json()

		return getTaskResultResponse.parse(data) as GetTaskResultResponse<TResult>
	}
}
