import { useCallback, useState } from "react"

import type { CallHighlight } from "../../../../../core/domain/Library.entity"
import { makeLibraryFolderWithCallHighlightPath } from "../../../../router/Router"

type CallHighlightEditionState =
	| {
			type: "edit-comment"
			callHighlight: CallHighlight
	  }
	| {
			type: "edit-parent-folder"
			callHighlight: CallHighlight
	  }

export function useCallHighlightEdition() {
	const [isOpen, setIsOpen] = useState(false)
	const [callHighlightEdition, setCallHighlightEdition] = useState<CallHighlightEditionState | null>(null)

	const closeCallHighlightModal = useCallback(() => {
		setIsOpen(false)
	}, [])

	const openCallHighlightCommentEditionModal = useCallback((callHighlight: CallHighlight) => {
		setCallHighlightEdition({ type: "edit-comment", callHighlight })
		setIsOpen(true)
	}, [])

	const openCallHighlightParentEditionModal = useCallback((callHighlight: CallHighlight) => {
		setCallHighlightEdition({ type: "edit-parent-folder", callHighlight })
		setIsOpen(true)
	}, [])

	const copyHighlightLink = useCallback((callHighlight: CallHighlight) => {
		navigator.clipboard.writeText(
			window.location.origin +
				makeLibraryFolderWithCallHighlightPath(callHighlight.containingLibraryFolderId, callHighlight.id),
		)
	}, [])

	return {
		isOpen,
		callHighlightEdition,
		copyHighlightLink,
		closeCallHighlightModal,
		openCallHighlightCommentEditionModal,
		openCallHighlightParentEditionModal,
	}
}
