import { z } from "zod"

import { ISODateTimeSchema } from "../infra/gateways/ISODateTime"

const DocMetaSchema = z.object({
	call_quotes: z
		.array(
			z.object({
				call_id: z.string(),
				startsAtMs: z.number(),
				endsAtMs: z.number(),
				quoted_text: z.string(),
			}),
		)
		.optional(),
})

export type DocMeta = z.infer<typeof DocMetaSchema>

export type DocElement = {
	metas?: DocMeta
	content: string | DocElement[]
	name?: string
}

const DocElementSchema: z.ZodSchema<DocElement> = z.lazy(() =>
	z.object({
		metas: DocMetaSchema.optional(),
		content: z.union([z.string(), z.array(z.lazy(() => DocElementSchema))]),
		name: z.string().optional(),
	}),
)

export const ChatSessionHistoryEntrySchema = z.object({
	from: z.enum(["ai", "user"]),
	createdAt: z.string(),
	content: DocElementSchema,
})

export type ChatSessionHistoryEntry = z.infer<typeof ChatSessionHistoryEntrySchema>

export const ChatSessionHistorySchema = z.array(ChatSessionHistoryEntrySchema)
export type ChatSessionHistory = z.infer<typeof ChatSessionHistorySchema>

export const ChatPropertiesSchema = z.object({
	id: z.string(),
	type: z.literal("call"),
	callId: z.string(),
	helloMessages: z.array(DocElementSchema),
	history: ChatSessionHistorySchema,
	createdAt: ISODateTimeSchema,
	waitingForAssistantSince: z.coerce.date().optional(),
})

export type ChatSessionProperties = z.infer<typeof ChatPropertiesSchema>

export class ChatSession {
	private constructor(private readonly _properties: ChatSessionProperties) {}

	public static fromProperties(properties: ChatSessionProperties) {
		return new ChatSession(properties)
	}

	public get properties() {
		return this._properties
	}

	public withAddedUserMessage(message: string): ChatSession {
		return ChatSession.fromProperties({
			...this.properties,
			history: [
				...this.properties.history,
				{
					from: "user",
					createdAt: new Date().toISOString(),
					content: {
						content: message,
						metas: undefined,
						name: undefined,
					},
				},
			],
		})
	}
}
