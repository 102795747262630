import { ArrowsPointingInIcon, ArrowsPointingOutIcon, SparklesIcon } from "@heroicons/react/20/solid"
import { UserIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"
import React from "react"
import Markdown from "react-markdown"

import { useLanguage } from "../../contexts/language.context"
import { Badge } from "../design-system/Badge.component"
import { Button } from "../design-system/Button.component"
import { Input } from "../design-system/Input"

export type ChatWindowMessage = {
	text: string
	from: "user" | "ai"
	date: Date
	asMarkdown?: boolean
}

type ChatMessageProps = Omit<ChatWindowMessage, "date">

function ChatMessage({ text, from, asMarkdown }: ChatMessageProps) {
	return (
		<div className="flex gap-3 my-4 text-gray-600 text-sm flex-1">
			<span className="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
				<div className="rounded-full bg-gray-100 border p-1">
					{from === "ai" ? (
						<SparklesIcon className="h-5 w-5 text-gray-600" />
					) : (
						<UserIcon className="h-5 w-5 text-gray-600" />
					)}
				</div>
			</span>
			<div className="leading-relaxed">
				<span className="block font-bold text-gray-700">{from === "ai" ? "Rippletide" : "You"} </span>
				{asMarkdown ? (
					<Markdown
						className={clsx("prose-sm prose-headings:my-2 prose-a:text-sky hover:prose-a:text-blue-500")}
					>
						{text}
					</Markdown>
				) : (
					text
				)}
			</div>
		</div>
	)
}

type ChatWindowProps = {
	messages: ChatWindowMessage[]
	asMarkdown?: boolean
	onUserMessage: (message: string) => void
}

function ChatWritingIndicator() {
	return (
		<div className="flex gap-3 my-4 text-gray-600 text-sm flex-1">
			<span className="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
				<div className="rounded-full bg-gray-100 border p-1">
					<SparklesIcon className="h-5 w-5 text-gray-600" />
				</div>
			</span>
			<div className="leading-relaxed">
				<span className="block font-bold text-gray-700">Rippletide</span>
				<div className="relative max-w-xs px-4 py-2 rounded-lg shadow bg-gray-200 mt-2">
					<div className="flex space-x-1 py-1 justify-center">
						<div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></div>
						<div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-150"></div>
						<div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-300"></div>
					</div>
				</div>
			</div>
		</div>
	)
}

export const ChatWindow = React.forwardRef<HTMLDivElement, ChatWindowProps>(
	({ messages, onUserMessage, asMarkdown }: ChatWindowProps, ref) => {
		const { t } = useLanguage()
		const [expanded, setExpanded] = React.useState(false)

		const [value, setValue] = React.useState("")
		const handleSubmit = React.useCallback(
			(event: React.FormEvent<HTMLFormElement>) => {
				event.preventDefault()
				onUserMessage(value)
				setValue("")
			},
			[onUserMessage, value],
		)

		const handleInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
			setValue(event.target.value)
		}, [])

		const lastMessage: ChatWindowMessage | null = messages[messages.length - 1] ?? null

		return (
			<div
				className={clsx(
					"flex flex-col justify-between z-[99] fixed bottom-[calc(4rem+1.5rem)] right-0 mr-4 bg-white p-4 rounded-lg border border-[#e5e7eb] shadow-xl",
					expanded ? "h-[90vh] w-[calc(100vw-2rem)]" : "w-[480px] h-[570px]",
				)}
			>
				<div className="flex flex-col space-y-1.5 pb-4">
					<div className="flex items-center justify-between">
						<div className="flex gap-2 items-center">
							<h2 className="font-semibold text-lg tracking-tight">{t("Rippletide copilot")}</h2>
							<Badge label={t("Beta")} variant="info" />
						</div>

						<button className="text-gray-600" onClick={() => setExpanded((prev) => !prev)}>
							{expanded ? (
								<ArrowsPointingInIcon className="h-4 w-4" />
							) : (
								<ArrowsPointingOutIcon className="h-4 w-4" />
							)}
						</button>
					</div>

					<p className="text-xs text-gray-500 leading-6">
						{t(
							"Ask me anything for further insights and help with this deal. You can refer back to the conversation at any time by coming back to this page.",
						)}
					</p>
				</div>
				<div className="pr-4 min-w-full overflow-y-auto flex-1" ref={ref}>
					{messages.map((message, index) => (
						<ChatMessage key={index} asMarkdown={asMarkdown} {...message} />
					))}
					{lastMessage !== null && lastMessage.from === "user" && (
						<div className="flex gap-3 my-4 text-gray-600 text-sm flex-1">
							<ChatWritingIndicator />
						</div>
					)}
				</div>
				<div className="flex items-center pt-0 mt-2">
					<form className="flex items-center justify-center w-full space-x-2" onSubmit={handleSubmit}>
						<Input.Input
							className="!mt-0"
							type="text"
							placeholder={t("Type your message")}
							name="message"
							value={value}
							onChange={handleInputChange}
							autoComplete="off"
						/>
						<Button
							disabled={value === ""}
							type="submit"
							eventName={"Chat window: send message clicked"}
							variant="primary"
						>
							{t("Send")}
						</Button>
					</form>
				</div>
			</div>
		)
	},
)
