import { z } from "zod"

export const tagTypeScopeSchema = z.enum(["call", "excerpt"])

export type TagTypeScope = z.infer<typeof tagTypeScopeSchema>

export const tagTypePropertiesSchema = z.object({
	id: z.string(),
	name: z.string(),
	slug: z.string().nullish(),
	description: z.string(),
	color: z.string(),
	scopes: z.array(tagTypeScopeSchema),
	isDefault: z.boolean(),
	workspaceWide: z.boolean(),
})

export const tagTypesSchema = z.array(tagTypePropertiesSchema)

export type TagTypeProperties = z.infer<typeof tagTypePropertiesSchema>

export class TagType {
	private constructor(public properties: TagTypeProperties) {}

	public static fromProperties(properties: TagTypeProperties) {
		return new TagType(properties)
	}

	public isCallTag() {
		return this.properties.scopes.includes("call")
	}

	public isExcerptTag() {
		return this.properties.scopes.includes("excerpt")
	}

	public isDefaultTag() {
		return this.properties.isDefault
	}
}

export const tagTypeWithTeamsSchema = z.object({
	tagType: tagTypePropertiesSchema,
	enabledTeamIds: z.array(z.string()),
})

export const tagTypesWithTeamsSchema = z.array(tagTypeWithTeamsSchema)

export type TagTypeWithEnabledTeamIds = {
	tagType: TagType
	enabledTeamIds: string[]
}
