import { DEMO_CALL_ID, DEMO_CALL_NAME } from "../../../core/domain/Call.entity"
import type { CallHighlight } from "../../../core/domain/Library.entity"
import type { TFunction } from "../../contexts/language.context"
import type { DateProvider } from "../time"

export const DEMO_HIGHLIGHT_ID = "demo-highlight-id"

export function makeDemoCallHighlight(dateProvider: DateProvider, t: TFunction): CallHighlight {
	return {
		id: DEMO_HIGHLIGHT_ID,
		callId: DEMO_CALL_ID,
		callName: DEMO_CALL_NAME,
		callDate: dateProvider.now(),
		startsAtMs: 143 * 1000,
		endsAtMs: 175 * 1000,
		callDurationSec: 185, // taken from demoCall.ts
		comment: t(
			"Save the best moments from your calls as highlights in your workspace sales training library, so you and your team can access them at any time!",
		),
		creatorFullName: "Rippletide",
		creationDate: dateProvider.now(),
		containingLibraryFolderId: "demoFolder",
		reactions: {
			thumbsUpCount: 0,
			thumbsDownCount: 0,
			userReaction: undefined,
		},
	}
}
