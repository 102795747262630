import { z } from "zod"

import { TagType } from "./TagType.entity"

export const callExcerptSchema = z.object({
	id: z.string(),
	callId: z.string(),
	tagType: z.object({
		id: z.string(),
		name: z.string(),
		description: z.string(),
		slug: z.string().nullable(),
		color: z.string(),
		scopes: z.array(z.enum(["call", "excerpt"])),
		isDefault: z.boolean(),
		createdAt: z.string(),
		lastModifiedAt: z.string(),
		workspaceWide: z.boolean(),
	}),
	startsAtMs: z.number(),
	endsAtMs: z.number(),
})

export const callExcerptListSchema = z.array(callExcerptSchema)

type CallExcerptProperties = z.infer<typeof callExcerptSchema>

export class CallExcerpt {
	public readonly tagType: TagType

	private constructor(public readonly properties: CallExcerptProperties) {
		this.tagType = TagType.fromProperties(properties.tagType)
	}

	public static fromProperties(properties: z.infer<typeof callExcerptSchema>) {
		return new CallExcerpt(properties)
	}

	public static fromValidProperties(properties: z.infer<typeof callExcerptSchema>) {
		return new CallExcerpt(properties)
	}
}
