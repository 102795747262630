import { useCallback, useEffect, useState } from "react"

import type { TagTypeWithEnabledTeamIds } from "../../core/domain/TagType.entity"
import { useDependencies } from "../contexts/dependencies.context"

export function useTagTypes() {
	const { tagTypesGateway } = useDependencies()
	const [tags, setTags] = useState<TagTypeWithEnabledTeamIds[] | "loading">("loading")

	useEffect(() => {
		tagTypesGateway.listTagTypesWithTeams().then(setTags)
	}, [tagTypesGateway])

	const refetchTagTypes = useCallback(() => {
		tagTypesGateway.listTagTypesWithTeams().then(setTags)
	}, [tagTypesGateway])

	return {
		tags,
		refetchTagTypes,
	}
}
