import type { DocElement } from "../../core/domain/ChatSession.entity"

export function documentElementToMarkdown(docElement: DocElement, nestingLevel = 0): string {
	const content = Array.isArray(docElement.content)
		? docElement.content.map((element) => documentElementToMarkdown(element, nestingLevel + 1)).join("")
		: docElement.content

	const name = docElement.name ? `${"#".repeat(nestingLevel)} ${docElement.name}: \n\n` : ""
	return `${name}${content}\n\n`
}
