import { ExclamationCircleIcon, ExclamationTriangleIcon } from "@heroicons/react/20/solid"
import React from "react"

import { Button } from "../../../components/design-system/Button.component"
import { useLanguage } from "../../../contexts/language.context"

export type BriefGeneratedErrorProps = {
	error: string
	hasReachedRateLimit?: boolean
	onRetry: () => void
}

export function BriefGeneratedError({ error, onRetry, hasReachedRateLimit }: BriefGeneratedErrorProps) {
	const { t } = useLanguage()
	const hasNotEnoughContextToBrief = error === "not_enough_context"

	if (hasReachedRateLimit) {
		return (
			<>
				<div className="p-8 max-w-md w-full text-center space-y-6">
					<ExclamationCircleIcon className="h-12 w-12 text-red-500 mx-auto" />
					<h1 className="text-2xl font-bold text-gray-800">{t("Limit exceeded")}</h1>
					<p className="text-gray-600">
						{t("You've exceeded the maximum number of briefs you can generate in a day.")}
					</p>
					<p className="text-gray-600">{t("Signup to Rippletide to get unlimited briefs!")}</p>
				</div>
			</>
		)
	}

	if (hasNotEnoughContextToBrief) {
		return (
			<>
				<div className="p-8 max-w-md w-full text-center space-y-6">
					<ExclamationTriangleIcon className="h-12 w-12 text-orange-500 mx-auto" />
					<h1 className="text-2xl font-bold text-gray-800">{t("We could not generate your brief")}</h1>
					<p className="text-gray-600">
						{t("Unfortunately, we could not find enough information to generate your meeting brief.")}
					</p>
					<p className="text-gray-600">{t("You can click the button below to try again.")}</p>
					<Button eventName="Briefing: try again clicked" variant="primary" onClick={onRetry}>
						{t("Retry")}
					</Button>
				</div>
			</>
		)
	}

	return (
		<>
			<div className="p-8 max-w-md w-full text-center space-y-6">
				<ExclamationCircleIcon className="h-12 w-12 text-red-500 mx-auto" />
				<h1 className="text-2xl font-bold text-gray-800">{t("Something went wrong")}</h1>
				<p className="text-gray-600">{t("We couldn't generate your meeting brief. Please try again later.")}</p>
				<Button eventName="Briefing: try again clicked" variant="primary" onClick={onRetry}>
					{t("Retry")}
				</Button>
			</div>
		</>
	)
}
