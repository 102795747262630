import React from "react"

import { Modal } from "../../../components/design-system/Modal.component"
import { ObjectionsRow } from "../../../components/shared/Objections/ObjectionRow.component"
import { useLanguage } from "../../../contexts/language.context"
import { DateFormatter, DateProvider } from "../../../utils/time"
import type { SelectedReportEntry } from "../HighImpactObjections.page"

type ObjectionReportModalProps = {
	objectionReportEntry: SelectedReportEntry
	handleClose: () => void
}

export function ObjectionReportModal({ objectionReportEntry, handleClose }: ObjectionReportModalProps) {
	const { t, language } = useLanguage()
	const dateProvider = new DateProvider()
	const dateFormatter = new DateFormatter(dateProvider, t)
	const reportMonth = dateFormatter.formatDate(objectionReportEntry.date, language, {
		month: "long",
		year: "numeric",
	})

	return (
		<Modal open={true} onClose={handleClose} className="!max-w-4xl">
			<h2 className="text-lg font-semibold text-gray-800">
				{t("High impact objections")} - {reportMonth}
			</h2>
			{objectionReportEntry.report ? (
				<div className="flex-1 flex flex-col gap-2 mt-8">
					{objectionReportEntry.report.sections.map((section, i) => (
						<ObjectionsRow
							sectionIndex={i}
							className="flex-1"
							key={section.title}
							objection={section}
							isDefaultCollapsed={i === 0}
							totalCallsRecordedInReportRange={objectionReportEntry.report?.callsCount}
							hidePercentIncrease={true}
						/>
					))}
				</div>
			) : (
				<div className="mt-8 flex-1 flex flex-col text-center">
					<p className="text-md font-semibold text-gray-800">
						{t("You did not record enough calls this month to generate a report")}
					</p>
					<p className="text-md text-gray-600 mt-2">
						{t(
							"There must be at least 6 new calls recorded in your workspace each month to generate an objection report.",
						)}
					</p>
				</div>
			)}
		</Modal>
	)
}
