import { CheckCircleIcon } from "@heroicons/react/20/solid"
import React from "react"
import { useNavigate } from "react-router-dom"

import { Button } from "../../../components/design-system/Button.component"
import { useLanguage } from "../../../contexts/language.context"
import { inviteTokenQueryParam } from "../../../hooks/useInviteToken"
import { makeClaimUrl } from "../../../router/Router"

type BriefGeneratedSuccessProps = {
	email: string
	claimUrl: string | null
}

export function BriefGeneratedState({ email, claimUrl }: BriefGeneratedSuccessProps) {
	const { t } = useLanguage()
	const navigate = useNavigate()

	const handleClaimAccountClick = React.useCallback(
		(claimUrl: string) => {
			const parsedUrl = new URL(claimUrl)
			const isValidClaimUrl = parsedUrl.pathname === "/join"
			const claimToken = parsedUrl.searchParams.get(inviteTokenQueryParam)

			if (!isValidClaimUrl || !claimToken) {
				console.error("Invalid claim URL", claimUrl)
				return
			}

			const isInIframe = window.self !== window.top
			if (isInIframe) {
				window.parent.postMessage({ type: "claimAccount", claimUrl }, "*")
			} else {
				navigate(makeClaimUrl(claimToken))
			}
		},
		[navigate],
	)

	return (
		<>
			<div className="p-8 max-w-md w-full text-center space-y-6">
				<CheckCircleIcon className="h-12 w-12 text-green-500 mx-auto" />
				<h1 className="text-2xl font-bold text-gray-800">{t("Your brief is ready!")}</h1>
				<p className="text-gray-600">
					{t(
						"Your meeting brief has been successfully generated and sent to {{email}}. Please check your inbox for the details.",
						{ email },
					)}
				</p>
				{claimUrl && (
					<div className="border-t border-gray-200 pt-4 space-y-4">
						<p className="text-gray-600">
							{t("Claim your Rippletide account to automatically get briefed for your future meetings.")}
						</p>
						<Button
							eventName="Public brief: claim account clicked"
							variant="primary"
							onClick={() => handleClaimAccountClick(claimUrl)}
						>
							{t("Sign up")}
						</Button>
					</div>
				)}
			</div>
		</>
	)
}
