import React from "react"

import type { Call } from "../../../../../core/domain/Call.entity"
import { ObjectionsRow } from "../../../../components/shared/Objections/ObjectionRow.component"

export type ObjectionsPanelProps = {
	call: Call
}

export function ObjectionsPanel({ call }: ObjectionsPanelProps) {
	if (!call.props.objections?.length) {
		return null
	}

	return (
		<div className="flex flex-col gap-4 py-2">
			{call.props.objections.map((objection, i) => (
				<ObjectionsRow
					sectionIndex={i}
					key={objection.title}
					objection={objection}
					call={call}
					isDefaultCollapsed={i === 0}
				/>
			))}
		</div>
	)
}
