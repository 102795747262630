export function isPersonalEmailDomain(domainName: string): boolean {
	const domainNameWithoutTLD = domainName.split(".")[0]
	const personalEmailDomainProviders = [
		"gmail",
		"yahoo",
		"outlook",
		"hotmail",
		"icloud",
		"live",
		"aol",
		"wanadoo",
		"free",
		"msn",
		"mail",
		"yandex",
		"orange",
		"sfr",
		"laposte",
		"bbox",
		"numericable",
		"neuf",
		"alice",
		"club-internet",
		"gmx",
		"web",
		"t-online",
		"arcor",
		"freenet",
		"online",
		"netcologne",
		"unitybox",
		"versanet",
		"versatel",
	]
	return personalEmailDomainProviders.includes(domainNameWithoutTLD)
}

export function isPersonalEmail(email: string): boolean {
	const domainName = email.split("@")[1]
	return isPersonalEmailDomain(domainName)
}

export function isEmail(maybeEmail: string): boolean {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
	return emailRegex.test(maybeEmail)
}
