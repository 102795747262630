import { Crisp } from "crisp-sdk-web"
import React, { useCallback, useEffect } from "react"

import { isAuthenticationAuthenticatedState, useAuthentication } from "./contexts/authentication.context"

/**
 * Docs: https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/npm/
 */
const CRISP_WEBSITE_ID = "a95bbdac-c421-4abc-89c3-e3b8eacf4bc0"

Crisp.configure(CRISP_WEBSITE_ID, {
	sessionMerge: true,
})

const SupportWidgetContext = React.createContext<
	| {
			isWidgetVisible: boolean
			loadChat: () => void
	  }
	| undefined
>(undefined)

export function SupportWidgetProvider({ children }: { children: React.ReactNode }) {
	const [crispRef, setCrispRef] = React.useState<HTMLElement | null>(null)
	const [isChatVisibilityOverridden, setIsChatVisibilityOverridden] = React.useState(false)
	const { authenticationState } = useAuthentication()
	const isWidgetVisible = !isAuthenticationAuthenticatedState(authenticationState)

	useEffect(() => {
		const interval = setInterval(() => {
			const widget = document.getElementById("crisp-chatbox")

			if (widget && crispRef !== widget) {
				setCrispRef(widget)
			}
		}, 200)

		return () => clearInterval(interval) // Cleanup on unmount
	}, [crispRef])

	useEffect(() => {
		if (crispRef) {
			const displayValue = isWidgetVisible || isChatVisibilityOverridden ? "block" : "none"
			crispRef.style.setProperty("display", displayValue, "important")
		}
	}, [crispRef, isChatVisibilityOverridden, isWidgetVisible])

	useEffect(() => {
		const onChatClosed = () => {
			setIsChatVisibilityOverridden(false)
		}
		Crisp.chat.onChatClosed(onChatClosed)

		return () => {
			Crisp.chat.offChatClosed()
		}
	}, [])

	const loadChat = useCallback(() => {
		setIsChatVisibilityOverridden(true)
		Crisp.chat.open()
	}, [])

	return (
		<SupportWidgetContext.Provider
			value={{ isWidgetVisible: isWidgetVisible || isChatVisibilityOverridden, loadChat }}
		>
			{children}
		</SupportWidgetContext.Provider>
	)
}

export function useSupportWidget() {
	const context = React.useContext(SupportWidgetContext)
	if (!context) {
		throw new Error("useSupportWidget must be used within a SupportWidgetProvider")
	}

	return context
}

export function SupportWidget() {
	const { authenticationState } = useAuthentication()

	useEffect(() => {
		if (isAuthenticationAuthenticatedState(authenticationState)) {
			const { user, workspace } = authenticationState
			Crisp.user.setEmail(user.email)
			Crisp.user.setNickname(user.firstName)
			Crisp.setTokenId(user.id)
			Crisp.session.setData({
				user_id: user.id,
				workspace_id: workspace.id,
				first_name: user.firstName,
				last_name: user.lastName,
			})
		} else {
			Crisp.setTokenId()
			Crisp.session.reset()
		}

		$crisp.push(["config", "container:index", [3]]) // Make it appear behind ToastContainer
	}, [authenticationState])

	return null
}
