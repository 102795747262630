import React, { type FormEvent } from "react"
import { HexColorPicker } from "react-colorful"

import type { EditTagTypePayload } from "../../../../../core/application/gateways/tag-types.gateway"
import type { TagType } from "../../../../../core/domain/TagType.entity"
import type { Team } from "../../../../../core/domain/Team.entity"
import { Badge } from "../../../../components/design-system/Badge.component"
import { Button } from "../../../../components/design-system/Button.component"
import { Input } from "../../../../components/design-system/Input"
import { Modal } from "../../../../components/design-system/Modal.component"
import { useLanguage } from "../../../../contexts/language.context"
import { TeamsDropdown } from "./TeamsDropDown.component"

type EditTagModalProps = {
	editingTag: TagType
	teams: Array<{ team: Team; enabled: boolean }>
	onClose: () => void
	onSubmit: (payload: EditTagTypePayload) => void
}

export function EditTagModal({ editingTag, teams: initialTeams, onClose, onSubmit }: EditTagModalProps) {
	const [description, setDescription] = React.useState(editingTag.properties.description)
	const [color, setColor] = React.useState(editingTag.properties.color)
	const [teams, setTeams] = React.useState(initialTeams)
	const [allUsersEnabled, setAllUsersEnabled] = React.useState(editingTag.properties.workspaceWide)

	const { t } = useLanguage()

	const handleTeamChange = React.useCallback(
		(teamId: string, enabled: boolean) => {
			const updatedTeams = teams.map((team) => (team.team.id === teamId ? { team: team.team, enabled } : team))
			setTeams(updatedTeams)

			if (updatedTeams.some((team) => team.enabled)) {
				setAllUsersEnabled(false)
			} else {
				setAllUsersEnabled(true)
			}
		},
		[teams],
	)

	const handleAllUsersEnabledChange = React.useCallback((enabled: boolean) => {
		setTeams((prevTeams) => prevTeams.map((team) => ({ team: team.team, enabled: !enabled })))
		setAllUsersEnabled(enabled)
	}, [])

	const handleSubmit = React.useCallback(
		(e: FormEvent<HTMLFormElement>) => {
			e.preventDefault()
			onSubmit({
				description,
				color,
				enabledTeamIds: teams.filter((team) => team.enabled).map((team) => team.team.id),
			})
		},
		[color, description, teams, onSubmit],
	)

	React.useEffect(() => {
		return () => {
			setDescription(editingTag.properties.description)
			setColor(editingTag.properties.color)
		}
	}, [editingTag])

	return (
		<Modal open={true} onClose={onClose} showCloseButton={false}>
			<p className="text-lg font-semibold text-navy-900 mb-6">
				{t(editingTag.isDefaultTag() ? "View tag" : "Edit tag")}
				<Badge label={editingTag.properties.name} variant="custom" color={color} className="ml-2" />
			</p>

			<div className="h-full flex flex-col">
				<form onSubmit={handleSubmit}>
					<Input.Group>
						<Input.Label>{t("Name")}</Input.Label>
						<Input.Input value={editingTag.properties.name} type="text" disabled placeholder={t("Name")} />
					</Input.Group>

					<Input.Group>
						<Input.Label>{t("Description")}</Input.Label>
						<Input.TextArea
							value={description}
							placeholder={t(
								"E.g., 'Discovery call focused on identifying the client's goals, pain points, and requirements to assess how our solutions can support their success.'",
							)}
							onChange={(e) => setDescription(e.target.value)}
							required
							disabled={editingTag.isDefaultTag()}
						/>
						<Input.Helper>
							{t(
								"Provide a brief description that explains when this tag should be applied. This helps our AI identify relevant moments in your calls.",
							)}
						</Input.Helper>
					</Input.Group>

					{editingTag.isDefaultTag() && (
						<Input.Helper className="mt-4">{t("This tag applies to all users.")}</Input.Helper>
					)}

					{!editingTag.isDefaultTag() && (
						<Input.Group>
							<Input.Label>{t("Teams (optional)")}</Input.Label>
							<TeamsDropdown
								allUsersEnabled={allUsersEnabled}
								teams={teams}
								onChange={handleTeamChange}
								onAllUsersChange={handleAllUsersEnabledChange}
							/>
						</Input.Group>
					)}

					{!editingTag.isDefaultTag() && (
						<Input.Group>
							<Input.Label>{t("Color")}</Input.Label>
							<HexColorPicker color={color} onChange={setColor} />
						</Input.Group>
					)}

					<div className="flex flex-1 justify-end items-center">
						{editingTag.isDefaultTag() ? (
							<Button
								className="mt-4"
								eventName="Close tag visualisation modal button clicked"
								variant="primary"
								onClick={onClose}
							>
								{t("Close")}
							</Button>
						) : (
							<Button
								className="mt-4"
								eventName="Create tag button clicked"
								type="submit"
								variant="primary"
								disabled={!description || !color}
							>
								{t("Edit tag")}
							</Button>
						)}
					</div>
				</form>
			</div>
		</Modal>
	)
}
