import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid"
import React from "react"

import { useLanguage } from "../../../contexts/language.context"
import { useSupportWidget } from "../../../SupportWidget"
import { TrackingButton } from "../../design-system/TrackingButton.component"
import { TrackingExternalHref } from "../../design-system/TrackingExternalHref.component"

export function HelpMenu() {
	const { loadChat } = useSupportWidget()
	const { t } = useLanguage()

	return (
		<Menu as="div" className="relative inline-block text-left">
			<div>
				<MenuButton className="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
					<QuestionMarkCircleIcon className="h-6 w-6 text-gray-200" />
				</MenuButton>
			</div>

			<MenuItems
				transition
				className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
			>
				<div className="py-1">
					<MenuItem>
						<TrackingExternalHref
							href="https://help.rippletide.com/"
							eventName="Help menu: help center clicked"
							className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
						>
							{t("Help centre")}
						</TrackingExternalHref>
					</MenuItem>
					<MenuItem>
						<TrackingButton
							eventName="Help menu: chat with us clicked"
							className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 w-full text-left"
							onClick={loadChat}
						>
							{t("Chat with us")}
						</TrackingButton>
					</MenuItem>
				</div>
			</MenuItems>
		</Menu>
	)
}
