import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid"
import assertNever from "assert-never"
import clsx from "clsx"
import React from "react"

import type {
	BriefAttendee,
	GenerateBriefParams,
	MeetingType,
} from "../../../../core/application/gateways/public-brief.gateway"
import { Button } from "../../../components/design-system/Button.component"
import { Input } from "../../../components/design-system/Input"
import { type TranslationKey, useLanguage } from "../../../contexts/language.context"
import { isEmail, isPersonalEmail } from "../../../utils/email"
import { DateProvider } from "../../../utils/time"

type AttendeeInput = {
	firstName: string
	lastName: string
	email: string
}

const allMeetingTypes: MeetingType[] = ["sales-demo", "other"]

function meetingTypeToLabel(meetingType: MeetingType): TranslationKey {
	switch (meetingType) {
		case "sales-demo":
			return "Sales demo"
		case "internal-meeting":
			return "Internal meeting"
		case "sales-follow-up":
			return "Sales follow-up"
		case "sales-discovery":
			return "Sales discovery"
		case "other":
			return "Other"
		default:
			assertNever(meetingType)
	}
}

type PublicBriefFormProps = {
	onSubmit: (payload: GenerateBriefParams) => void
	isSubmitting: boolean
}

export function PublicBriefForm({ onSubmit, isSubmitting }: PublicBriefFormProps) {
	const [attendees, setAttendees] = React.useState<AttendeeInput[]>([
		{ firstName: "", lastName: "", email: "" },
		{ firstName: "", lastName: "", email: "" },
	])
	const [email, setEmail] = React.useState("")
	const [meetingTitle, setMeetingTitle] = React.useState("")
	const [meetingType, setMeetingType] = React.useState<MeetingType | "">("sales-demo")
	const { t } = useLanguage()
	const [organizerIndex, setOrganizerIndex] = React.useState<number | null>(null)
	const [yourIndex, setYourIndex] = React.useState<number | null>(0)
	const [areTOSAccepted, setTOSAccepted] = React.useState(false)

	const handleSetYourIndex = React.useCallback(
		(index: number | null) => {
			if (index === null) {
				setYourIndex(null)
				return
			}

			const attendee = attendees[index]
			if (attendee?.email && !email && isEmail(attendee.email)) {
				setEmail(attendee.email)
			}
			setYourIndex(index)
		},
		[attendees, email],
	)

	const canAddAttendee =
		attendees.length < 3 && attendees.every((attendee) => attendee.firstName && attendee.lastName && attendee.email)

	const isYouTicked = yourIndex !== null

	const canGenerateInsights =
		attendees.length > 0 &&
		attendees.every(
			(attendee) => attendee.firstName && attendee.lastName && attendee.email && isEmail(attendee.email),
		) &&
		email &&
		meetingTitle &&
		meetingType &&
		areTOSAccepted &&
		isEmail(email) &&
		isYouTicked

	const handleFormSubmit = React.useCallback(
		async (e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault()
			const dateProvider = new DateProvider()
			const attendeesWithRoles: BriefAttendee[] = attendees.map((attendee, index) => ({
				firstName: attendee.firstName,
				lastName: attendee.lastName,
				email: attendee.email,
				isOrganizer: organizerIndex === index,
				isYou: yourIndex === index,
			}))

			onSubmit({
				meetingTitle,
				meetingType: meetingType || null,
				attendees: attendeesWithRoles,
				email,
				language: dateProvider.getLanguageCode(),
			})
		},
		[attendees, onSubmit, meetingTitle, meetingType, email, organizerIndex, yourIndex],
	)

	return (
		<form onSubmit={handleFormSubmit}>
			<div className="flex sm:flex-row flex-col items-baseline justify-center gap-2">
				<Input.Group className="flex-[2]">
					<Input.Label htmlFor="meetingTitle">{t("Meeting title")}</Input.Label>
					<Input.Input
						type="text"
						id="meetingTitle"
						placeholder={t("Sales Demo with ACME Corp.")}
						value={meetingTitle}
						onChange={(e) => {
							setMeetingTitle(e.target.value)
						}}
					/>
					<Input.Helper>
						{t(
							"Providing a title will help us understand the context of the meeting and provide better insights.",
						)}
					</Input.Helper>
				</Input.Group>
				<Input.Group className="w-full flex-[1]">
					<Input.Label htmlFor="meetingTitle">{t("Meeting type")}</Input.Label>
					<Input.Select
						value={meetingType}
						onChange={(e) => {
							setMeetingType(e.target.value as MeetingType)
						}}
						required
					>
						<Input.SelectOption value="" disabled>
							{t("Select a meeting type")}
						</Input.SelectOption>
						{allMeetingTypes.map((type) => (
							<Input.SelectOption
								key={type}
								value={type}
								onChange={() => {
									setMeetingType(type)
								}}
							>
								{t(meetingTypeToLabel(type))}
							</Input.SelectOption>
						))}
					</Input.Select>
				</Input.Group>
			</div>

			<div>
				<Input.Group>
					<Input.Label htmlFor="meetingNotes">{t("Attendees")}</Input.Label>
					<Input.Helper>
						{t(
							"Emails will not be stored and are only used to generate the insights. We will not contact your attendees.",
						)}
					</Input.Helper>
					<Input.Helper className={clsx(!isYouTicked && "!text-orange-500")}>
						{t("Make sure to tick the 'You' checkbox for the attendee that represents you in the meeting.")}
					</Input.Helper>
					{attendees.map((attendee, index) => {
						const currentEmail = attendee.email
						const hasInvalidEmail = isEmail(currentEmail) && isPersonalEmail(currentEmail)

						return (
							<div className="flex items-baseline gap-2" key={index}>
								<Input.Input
									type="text"
									placeholder={t("First name")}
									value={attendee.firstName}
									className="flex-1"
									onChange={(e) => {
										setAttendees((prevAttendees) => {
											const newAttendees = [...prevAttendees]
											newAttendees[index].firstName = e.target.value
											return newAttendees
										})
									}}
								/>
								<Input.Input
									type="text"
									placeholder={t("Last name")}
									value={attendee.lastName}
									className="flex-1"
									onChange={(e) => {
										setAttendees((prevAttendees) => {
											const newAttendees = [...prevAttendees]
											newAttendees[index].lastName = e.target.value
											return newAttendees
										})
									}}
								/>
								<Input.Group className="flex-1">
									<Input.Input
										type="email"
										placeholder="Email"
										value={attendee.email}
										className={hasInvalidEmail ? "!ring-red-500" : ""}
										onChange={(e) => {
											setAttendees((prevAttendees) => {
												const newAttendees = [...prevAttendees]
												newAttendees[index].email = e.target.value
												return newAttendees
											})
										}}
										required
									/>
									<Input.Helper className={clsx("!text-xs", hasInvalidEmail && "!text-red-500")}>
										{t("Only professional emails are supported.")}
									</Input.Helper>
								</Input.Group>

								<div className="flex items-center gap-2">
									<div>
										<Input.CheckboxGroup
											id={`organizer-${index}`}
											mainText={t("Organizer")}
											checked={organizerIndex === index}
											onChange={(e) => {
												setOrganizerIndex(e.target.checked ? index : null)
											}}
											mainTextClassName="!font-normal"
										/>
									</div>
									<div>
										<Input.CheckboxGroup
											id={`your-${index}`}
											mainText={t("You")}
											checked={yourIndex === index}
											onChange={(e) => {
												handleSetYourIndex(e.target.checked ? index : null)
											}}
											mainTextClassName="!font-normal"
										/>
									</div>
								</div>

								<button
									type="button"
									disabled={attendees.length <= 2}
									className="disabled:cursor-not-allowed disabled:opacity-50"
									onClick={() =>
										setAttendees((prevAttendees) => prevAttendees.filter((_, i) => i !== index))
									}
								>
									<TrashIcon className="h-4 w-4 text-gray-500" />
								</button>
							</div>
						)
					})}
					<div className="flex items-center justify-center mt-4">
						<Button
							size="xs"
							variant="default"
							type="button"
							onClick={() => {
								setAttendees((prevAttendees) => {
									return [...prevAttendees, { firstName: "", lastName: "", email: "" }]
								})
							}}
							disabled={!canAddAttendee}
							eventName="Add attendee clicked"
						>
							{t("Add attendee")}
							<PlusIcon className="h-4 w-4" />
						</Button>
					</div>
				</Input.Group>
			</div>

			<div className="relative mt-6 pb-2">
				<div aria-hidden="true" className="absolute inset-0 flex items-center">
					<div className="w-full border-t border-gray-200" />
				</div>
			</div>

			<Input.Group>
				<Input.Label htmlFor="email">{t("Your email")}</Input.Label>
				<Input.Input
					type="email"
					id="email"
					placeholder="Email"
					value={email}
					onChange={(e) => {
						setEmail(e.target.value)
					}}
					required
				/>
				<Input.Helper>{t("We'll send the insights to this email address.")}</Input.Helper>
			</Input.Group>

			<Input.Group>
				<Input.CheckboxGroup
					id="tos"
					mainText={t(
						"I certify that I am the owner of this email address and authorise its use to send me the generated brief and any related communications.",
					)}
					checked={areTOSAccepted}
					onChange={(event) => setTOSAccepted(event.target.checked)}
				/>
			</Input.Group>

			<div className="mt-4 flex items-center justify-center">
				<Button
					disabled={!canGenerateInsights || isSubmitting}
					eventName="Public brief form submitted"
					variant="success"
					type="submit"
				>
					{t(isSubmitting ? "Generating insights..." : "Generate my meeting insights")}
				</Button>
			</div>
		</form>
	)
}
