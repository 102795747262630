import { IdentificationIcon, UsersIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"
import { Link } from "react-router-dom"

import { makeAdminLinkedinIdentitiesPath, makeAdminUsersListPath } from "../../router/Router"

export type AdminNavigationTabsProps = {
	origin: "create-workspace" | "enrichments" | "workspaces" | "linkedin-identities"
}

export function AdminNavigationTabs({ origin }: AdminNavigationTabsProps) {
	const tabs = [
		{
			name: "Workspaces",
			link: makeAdminUsersListPath(),
			icon: UsersIcon,
			current: origin === "workspaces",
		},
		{
			name: "LinkedIn identities",
			link: makeAdminLinkedinIdentitiesPath(),
			icon: IdentificationIcon,
			current: origin === "linkedin-identities",
		},
	]

	return (
		<div className="border-b border-gray-200 mt-4">
			<nav className="-mb-px flex space-x-8" aria-label="Tabs">
				{tabs.map((tab) => (
					<Link
						key={tab.name}
						to={tab.link}
						className={clsx(
							tab.current
								? "border-navy-500 text-navy-700"
								: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
							"group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium",
						)}
						aria-current={tab.current ? "page" : undefined}
					>
						<tab.icon
							className={clsx(
								tab.current ? "text-navy-500" : "text-gray-400 group-hover:text-gray-500",
								"-ml-0.5 mr-2 h-5 w-5",
							)}
							aria-hidden="true"
						/>
						<span>{tab.name}</span>
					</Link>
				))}
			</nav>
		</div>
	)
}
