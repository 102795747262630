import type {
	CreateTagTypePayload,
	EditTagTypePayload,
	ITagTypesGateway,
} from "../../application/gateways/tag-types.gateway"
import {
	TagType,
	tagTypesSchema,
	tagTypesWithTeamsSchema,
	type TagTypeWithEnabledTeamIds,
} from "../../domain/TagType.entity"

export class HttpTagTypesGateway implements ITagTypesGateway {
	constructor(private readonly baseApiUrl: string) {}

	public async listTagTypes() {
		const endpointUrl = `${this.baseApiUrl}/tag-types`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()
		const tagTypes = tagTypesSchema.parse(json)

		return tagTypes.map((tagType) => TagType.fromProperties(tagType))
	}

	public async listTagTypesWithTeams(): Promise<TagTypeWithEnabledTeamIds[]> {
		const endpointUrl = `${this.baseApiUrl}/tag-types/with-teams`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()
		const tagTypes = tagTypesWithTeamsSchema.parse(json)

		return tagTypes.map(
			(tagTypeWithTeams): TagTypeWithEnabledTeamIds => ({
				tagType: TagType.fromProperties(tagTypeWithTeams.tagType),
				enabledTeamIds: tagTypeWithTeams.enabledTeamIds,
			}),
		)
	}

	public async deleteTagType(tagType: TagType) {
		const endpointUrl = `${this.baseApiUrl}/tag-types/${tagType.properties.id}`
		await fetch(endpointUrl, {
			method: "DELETE",
			credentials: "include",
		})
	}

	public async createTagType(payload: CreateTagTypePayload) {
		const endpointUrl = `${this.baseApiUrl}/tag-types`
		await fetch(endpointUrl, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(payload),
		})
	}

	public async editTagType(tagType: TagType, payload: EditTagTypePayload) {
		const endpointUrl = `${this.baseApiUrl}/tag-types/${tagType.properties.id}`
		await fetch(endpointUrl, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(payload),
		})
	}
}
