import React from "react"

import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { useAuthenticatedSession } from "../../../../contexts/authentication.context"
import { useLanguage } from "../../../../contexts/language.context"
import { NextBillingCard } from "./NextBillingCard.component"
import { PlanCard } from "./PlanCard.component"
import { PlanUpdateSuccessModal } from "./PlanUpdateSuccessModal.component"
import { PricingTable } from "./PricingTable.component"
import { useBillingSettings } from "./useBillingSettings"
import { YourPlanCard } from "./YourPlanCard.component"

export function BillingSettings() {
	const { billing } = useBillingSettings()
	const { user } = useAuthenticatedSession()
	const isWorkspaceManager = user.isWorkspaceManager()
	const { t } = useLanguage()

	if (billing.status === "loading" || billing.status === "idle") {
		return (
			<div className="flex items-center justify-center">
				<SpinnerIcon className="w-10 h-10 text-gray-400 animate-spin" />
			</div>
		)
	}

	if (billing.status === "error") {
		return <div>Error: {billing.error.message}</div>
	}

	if (billing.status !== "loaded" || !billing.billing.plan) {
		return (
			<div>
				<div className="grid grid-cols-2 gap-8">
					<PlanCard title={t("Your plan")} subtitle="Free">
						{!isWorkspaceManager && (
							<span className="text-navy-700 text-opacity-50 font-medium mt-4">
								{t("Plan changes can only be made by your workspace owner.")}
							</span>
						)}
					</PlanCard>
				</div>
				{isWorkspaceManager && (
					<PricingTable
						currentPlanId={billing.status === "loaded" ? billing.billing.plan?.id ?? null : null}
						plans={billing.status === "loaded" ? billing.plans : []}
						isInTrial={false}
						hasPaymentMethod={false}
						billingCoupon={billing.status === "loaded" ? billing.billing.customer?.coupon : null}
					/>
				)}
			</div>
		)
	}
	const hasActiveSubscription = Boolean(billing.billing.subscription)
	const hasPlan = Boolean(billing.billing.plan)

	// display the pricing table if:
	// - has no plan
	// - has a plan and an active subscription
	const shouldDisplayPricingTable = isWorkspaceManager && (!hasPlan || (hasPlan && hasActiveSubscription))

	return (
		<div>
			{billing.billing.plan && billing.billing.subscription?.endAt && (
				<PlanUpdateSuccessModal
					planName={billing.billing.plan.name}
					subscriptionEndAt={billing.billing.subscription.endAt}
				/>
			)}
			<div className="grid grid-cols-2 gap-8">
				<YourPlanCard billing={billing.billing} />
				<NextBillingCard billing={billing.billing} />
			</div>
			{shouldDisplayPricingTable && (
				<PricingTable
					currentPlanId={billing.billing.plan.id}
					plans={billing.plans}
					isInTrial={Boolean(billing.billing.subscription?.isTrial)}
					hasPaymentMethod={Boolean(billing.billing.subscription?.hasPaymentMethod)}
					billingCoupon={billing.status === "loaded" ? billing.billing.customer?.coupon : null}
				/>
			)}
		</div>
	)
}
