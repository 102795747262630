import assertNever from "assert-never"
import { useParams } from "react-router-dom"

import { Layout } from "../../components/shared/Layout/Layout.component"
import { useAuthenticatedSession } from "../../contexts/authentication.context"
import { useLanguage } from "../../contexts/language.context"
import { useLibraryWithHighlights } from "../../hooks/useLibraryWithHighlights"
import { LibraryUpgradePlanOverlay } from "./components/LibraryUpgradePlanOverlay.component"
import { MainPanel } from "./components/MainPanel.component"
import { CallHighlightCommentEditionModal } from "./components/modals/CallHighlightCommentEditionModal.component"
import { CallHighlightParentEditionModal } from "./components/modals/CallHighlightParentEditionModal.component"
import {
	LibraryFolderEditionModal,
	type LibraryFolderEditionModalParams,
} from "./components/modals/LibraryFolderEditionModal.component"
import { SideLibraryFoldersNavigation } from "./components/SideLibraryFoldersNavigation/SideLibraryFoldersNavigation.component"
import type { CallHighlightActions } from "./components/types"
import { useCallHighlightEdition } from "./components/use/useCallHighlightEdition"
import { useLibraryFolderEdition } from "./components/use/useLibraryFolderEdition"

export function LibraryPage() {
	const { t } = useLanguage()
	const { libraryFolderId, callHighlightId } = useParams<{ libraryFolderId?: string; callHighlightId?: string }>()

	const {
		library,
		currentFolderHighlights,
		currentFolder,
		coachingSessions,
		createLibraryFolder,
		editLibraryFolder,
		deleteLibraryFolder,
		updateHighlight,
		deleteHighlight,
	} = useLibraryWithHighlights(libraryFolderId)

	const {
		isOpen: isCallHighlightEditionModalOpen,
		callHighlightEdition,
		closeCallHighlightModal,
		openCallHighlightCommentEditionModal,
		openCallHighlightParentEditionModal,
		copyHighlightLink,
	} = useCallHighlightEdition()

	const {
		isOpen: isLibraryFolderEditionModalOpen,
		libraryFolderEdition,
		closeLibraryFolderModal,
		openUserLibraryFolderCreationModal,
		openWorkspaceLibraryFolderCreationModal,
		openLibraryFolderNameEditionModal,
		openLibraryFolderParentEditionModal,
		openLibrarySubfolderCreationModal,
	} = useLibraryFolderEdition()

	const highlightActions: CallHighlightActions = {
		onHighlightCopyLink: copyHighlightLink,
		onHighlightEditComment: openCallHighlightCommentEditionModal,
		onHighlightEditParentFolder: openCallHighlightParentEditionModal,
		onHighlightDelete: deleteHighlight,
	}

	const { workspace } = useAuthenticatedSession()
	const isLibraryEnabled = workspace.isPlanFeatureEnabled("library")

	const libraryFolderEditionModalParams = ((): LibraryFolderEditionModalParams | undefined => {
		if (!libraryFolderEdition) return

		switch (libraryFolderEdition?.type) {
			case "create":
				return {
					type: "create",
					section: libraryFolderEdition.section,
					createLibraryFolder,
				}

			case "create-subfolder":
				return {
					type: "create-subfolder",
					createLibraryFolder,
					parentLibraryFolderId: libraryFolderEdition.parentLibraryFolderId,
				}

			case "edit-name":
				return {
					type: "edit-name",
					editLibraryFolder,
					libraryFolderUnderEdition: libraryFolderEdition.folder,
				}

			case "edit-parent-folder":
				return {
					type: "edit-parent-folder",
					editLibraryFolder,
					libraryFolderUnderEdition: libraryFolderEdition.folder,
				}

			default:
				assertNever(libraryFolderEdition)
		}
	})()

	return (
		<Layout pageName={t("Library")} isFluid className="!p-0 flex">
			{/* space.16 => height of the navbar */}
			<div className="flex-1 flex-grow flex min-h-[calc(100vh-theme(space.16))]">
				<div className="min-w-0 flex-1 bg-white xl:flex relative">
					{!isLibraryEnabled && <LibraryUpgradePlanOverlay />}
					<SideLibraryFoldersNavigation
						library={library}
						onLibraryFolderCreationInUserLibrary={openUserLibraryFolderCreationModal}
						onLibraryFolderCreationInWorkspaceLibrary={openWorkspaceLibraryFolderCreationModal}
						onLibraryFolderNameEdition={openLibraryFolderNameEditionModal}
						onLibraryFolderParentEdition={openLibraryFolderParentEditionModal}
						onLibraryFolderDeletion={deleteLibraryFolder}
						onLibrarySubfolderCreation={openLibrarySubfolderCreationModal}
						disabled={!isLibraryEnabled}
						currentFolderId={libraryFolderId}
						showCreateTrainingProgramButton={true}
					/>
					<MainPanel
						library={library}
						highlights={
							typeof currentFolderHighlights === "string"
								? currentFolderHighlights
								: currentFolderHighlights.highlights
						}
						newHighlightsAreLoading={
							typeof currentFolderHighlights === "string"
								? false
								: currentFolderHighlights.newOnesAreLoading
						}
						currentFolder={currentFolder}
						currentHighlightId={callHighlightId}
						coachingSessions={coachingSessions}
						highlightActions={highlightActions}
						disabled={!isLibraryEnabled}
					/>
				</div>
			</div>
			<LibraryFolderEditionModal
				library={library}
				onClose={closeLibraryFolderModal}
				isOpen={isLibraryFolderEditionModalOpen}
				params={libraryFolderEditionModalParams}
			/>
			<CallHighlightCommentEditionModal
				isOpen={isCallHighlightEditionModalOpen && callHighlightEdition?.type === "edit-comment"}
				onClose={closeCallHighlightModal}
				callHighlightUnderEdition={
					callHighlightEdition?.type === "edit-comment" ? callHighlightEdition.callHighlight : undefined
				}
				updateHighlight={updateHighlight}
			/>
			<CallHighlightParentEditionModal
				isOpen={isCallHighlightEditionModalOpen && callHighlightEdition?.type === "edit-parent-folder"}
				onClose={closeCallHighlightModal}
				callHighlightUnderEdition={
					callHighlightEdition?.type === "edit-parent-folder" ? callHighlightEdition.callHighlight : undefined
				}
				updateHighlight={updateHighlight}
				library={library}
			/>
		</Layout>
	)
}
