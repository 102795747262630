import React, { type FormEvent } from "react"
import { HexColorPicker } from "react-colorful"

import type { TagTypeScope } from "../../../../../core/domain/TagType.entity"
import type { Team } from "../../../../../core/domain/Team.entity"
import { Badge } from "../../../../components/design-system/Badge.component"
import { Button } from "../../../../components/design-system/Button.component"
import { Input } from "../../../../components/design-system/Input"
import { Modal } from "../../../../components/design-system/Modal.component"
import { useLanguage } from "../../../../contexts/language.context"
import { TeamsDropdown } from "./TeamsDropDown.component"

export type CreateTagModalPayload = {
	description: string
	color: string
	name: string
	scope: TagTypeScope
	workspaceWide: boolean
	enabledTeamIds: string[]
}

type CreateTagModalProps = {
	open: boolean
	onClose: () => void
	onSubmit: (payload: CreateTagModalPayload) => void
	teams: Team[]
}

export function CreateTagModal({ open, onClose, onSubmit, teams: initialTeams }: CreateTagModalProps) {
	const [name, setName] = React.useState("")
	const [description, setDescription] = React.useState("")
	const [color, setColor] = React.useState("#262656")
	const [scope, setScope] = React.useState<TagTypeScope>("call")
	const [teams, setTeams] = React.useState(initialTeams.map((team) => ({ team, enabled: false })))
	const [allUsersEnabled, setAllUsersEnabled] = React.useState(true)

	const { t } = useLanguage()

	const handleTeamChange = React.useCallback(
		(teamId: string, enabled: boolean) => {
			const updatedTeams = teams.map((team) => (team.team.id === teamId ? { team: team.team, enabled } : team))
			setTeams(updatedTeams)

			if (updatedTeams.some((team) => team.enabled)) {
				setAllUsersEnabled(false)
			} else {
				setAllUsersEnabled(true)
			}
		},
		[teams],
	)

	const handleAllUsersEnabledChange = React.useCallback((enabled: boolean) => {
		setTeams((prevTeams) => prevTeams.map((team) => ({ team: team.team, enabled: !enabled })))
		setAllUsersEnabled(enabled)
	}, [])

	const handleSubmit = React.useCallback(
		(e: FormEvent<HTMLFormElement>) => {
			e.preventDefault()
			onSubmit({
				name,
				description,
				color,
				scope,
				workspaceWide: allUsersEnabled,
				enabledTeamIds: teams.filter((team) => team.enabled).map((team) => team.team.id),
			})
		},
		[color, description, name, onSubmit, scope, teams, allUsersEnabled],
	)

	React.useEffect(() => {
		return () => {
			setName("")
			setDescription("")
			setColor("#262656")
		}
	}, [open])

	return (
		<Modal open={open} onClose={onClose} showCloseButton={false}>
			<p className="text-lg font-semibold text-navy-900 mb-6">
				{t("Create a new tag")}
				{name && <Badge label={name} variant="custom" color={color} className="ml-2" />}
			</p>

			<div className="h-full flex flex-col">
				<form onSubmit={handleSubmit}>
					<Input.Group>
						<Input.Label>{t("Name")}</Input.Label>
						<Input.Input
							value={name}
							type="text"
							placeholder={t("Name")}
							onChange={(e) => setName(e.target.value)}
							required
						/>
					</Input.Group>

					<Input.Group>
						<Input.Label>{t("Description")}</Input.Label>
						<Input.TextArea
							value={description}
							placeholder={t(
								"E.g., 'Discovery call focused on identifying the client's goals, pain points, and requirements to assess how our solutions can support their success.'",
							)}
							onChange={(e) => setDescription(e.target.value)}
							required
						/>
						<Input.Helper>
							{t(
								"Provide a brief description that explains when this tag should be applied. This helps our AI identify relevant moments in your calls.",
							)}
						</Input.Helper>
					</Input.Group>

					<Input.Group>
						<Input.Label>{t("Tag scope")}</Input.Label>
						<Input.Select value={scope} onChange={(e) => setScope(e.target.value as TagTypeScope)}>
							<Input.SelectOption value="call">{t("Entire call")}</Input.SelectOption>
							<Input.SelectOption value="excerpt">{t("Snippet only")}</Input.SelectOption>
						</Input.Select>
						<Input.Helper>
							{t(
								'For tags relevant to the whole call, choose "Entire call" - e.g. "Discovery call". For tags tied to specific moments, choose "Snippet only" - e.g. "Prospect objection".',
							)}
						</Input.Helper>
					</Input.Group>

					<Input.Group>
						<Input.Label>{t("Teams (optional)")}</Input.Label>
						<TeamsDropdown
							allUsersEnabled={allUsersEnabled}
							teams={teams}
							onChange={handleTeamChange}
							onAllUsersChange={handleAllUsersEnabledChange}
						/>
					</Input.Group>

					<Input.Group>
						<Input.Label>{t("Color")}</Input.Label>
						<HexColorPicker color={color} onChange={setColor} />
					</Input.Group>

					<div className="flex flex-1 justify-end items-center">
						<Button
							className="mt-4"
							eventName="Create tag button clicked"
							type="submit"
							variant="primary"
							disabled={!name || !description || !color || !scope}
						>
							{t("Create tag")}
						</Button>
					</div>
				</form>
			</div>
		</Modal>
	)
}
