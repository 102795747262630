import React from "react"

import { type AnalyticsProps, useAnalytics } from "../../contexts/analytics.context"

type TrackingButtonProps = {
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
	AnalyticsProps

export const TrackingButton = React.forwardRef<HTMLButtonElement, TrackingButtonProps>(
	({ onClick, children, eventName, eventProperties, ...props }, ref) => {
		const { setupEventTracking } = useAnalytics()
		const { trackEvent: trackButtonUsage, debugDOMProps } = setupEventTracking({ eventName, eventProperties })

		const handleTrackingClick = (e: React.MouseEvent<HTMLButtonElement>) => {
			console.debug("[TrackingButton] Tracking event:", eventName, eventProperties)
			trackButtonUsage()
			onClick?.(e)
		}

		return (
			<button {...props} ref={ref} onClick={handleTrackingClick} {...debugDOMProps}>
				{children}
			</button>
		)
	},
)

TrackingButton.displayName = "TrackingButton"
