import { Switch } from "@headlessui/react"
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid"
import clsx from "clsx"
import React from "react"

import { Tooltip } from "../Tooltip.component"

type InputGroupWrapperProps = {
	children: React.ReactNode
	className?: string
}

function InputGroupWrapper({ children, className }: InputGroupWrapperProps) {
	return <div className={clsx("mt-4", className)}>{children}</div>
}

type InputGroupLabelProps = React.LabelHTMLAttributes<HTMLLabelElement> & {
	tooltipContent?: string
}

function InputGroupLabel({ children, tooltipContent, ...htmlProps }: InputGroupLabelProps) {
	if (tooltipContent) {
		return (
			<div className="flex gap-1 items-center">
				<label
					{...htmlProps}
					className={clsx("block text-sm font-medium leading-6 text-gray-900", htmlProps.className)}
				>
					{children}
				</label>
				<Tooltip content={tooltipContent}>
					<QuestionMarkCircleIcon className="h-4 w-4 text-gray-400" />
				</Tooltip>
			</div>
		)
	}

	return (
		<label
			{...htmlProps}
			className={clsx("block text-sm font-medium leading-6 text-gray-900", htmlProps.className)}
		>
			{children}
		</label>
	)
}

type InputGroupInputProps = React.InputHTMLAttributes<HTMLInputElement>

function InputGroupInput({ className, ...htmlProps }: InputGroupInputProps) {
	return (
		<input
			{...htmlProps}
			className={clsx(
				"mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-navy-500 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6",
				className,
			)}
		/>
	)
}

type InputGroupTextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>

function inputGroupTextArea({ className, ...htmlProps }: InputGroupTextAreaProps) {
	return (
		<textarea
			{...htmlProps}
			className={clsx(
				"mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-navy-500 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6",
				className,
			)}
		/>
	)
}

type InputGroupSelectProps = React.InputHTMLAttributes<HTMLSelectElement>

function InputGroupSelect({ ...htmlProps }: InputGroupSelectProps) {
	return (
		<select
			{...htmlProps}
			className={clsx(
				"mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-navy-500 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6",
				htmlProps.className,
			)}
		/>
	)
}

type InputGroupSelectOptionProps = React.OptionHTMLAttributes<HTMLOptionElement>

function InputGroupSelectOption({ ...htmlProps }: InputGroupSelectOptionProps) {
	return <option {...htmlProps} />
}

type InputGroupHelperTextProps = {
	children: React.ReactNode
	className?: string
}

function InputGroupHelperText({ children, className }: InputGroupHelperTextProps) {
	return <p className={clsx("mt-2 text-sm text-gray-500", className)}>{children}</p>
}

type InputCheckboxGroupProps = React.InputHTMLAttributes<HTMLInputElement> & {
	mainText: React.ReactNode
	subText?: string
	mainTextClassName?: string
}

function InputCheckboxGroup({
	className,
	mainText,
	subText,
	mainTextClassName,
	id,
	...htmlProps
}: InputCheckboxGroupProps) {
	return (
		<div className="relative flex items-start">
			<div className="flex h-6 items-center">
				<input
					id={id}
					type="checkbox"
					className={clsx("h-4 w-4 rounded border-gray-300 text-sky focus:ring-sky", className)}
					{...htmlProps}
				/>
			</div>
			<div className="ml-3 text-sm leading-6">
				<label htmlFor={id} className={clsx("font-medium text-gray-900", mainTextClassName)}>
					{mainText}
				</label>{" "}
				{subText && <span className="text-gray-500">{subText}</span>}
			</div>
		</div>
	)
}

type InputGroupToggleProps = {
	value: boolean
	onChange: (value: boolean) => void
	disabled?: boolean
	size?: "small" | "medium" | "large"
	className?: string
}

function InputGroupToggle({ value, onChange, disabled = false, size = "medium", className }: InputGroupToggleProps) {
	const sizeClasses = {
		small: {
			switch: "h-4 w-8",
			thumb: "h-3 w-3 translate-x-0 group-data-[checked]:translate-x-4",
		},
		medium: {
			switch: "h-6 w-11",
			thumb: "h-5 w-5 translate-x-0 group-data-[checked]:translate-x-5",
		},
		large: {
			switch: "h-8 w-14",
			thumb: "h-7 w-7 translate-x-0 group-data-[checked]:translate-x-6",
		},
	}

	return (
		<div className={clsx("flex items-center", className)}>
			<Switch
				checked={value}
				onChange={onChange}
				disabled={disabled}
				className={clsx(
					"group relative inline-flex flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2",
					value && "bg-indigo-600",
					disabled ? "cursor-not-allowed opacity-30 bg-gray-300" : "bg-gray-400",
					sizeClasses[size].switch,
				)}
			>
				<span
					aria-hidden="true"
					className={clsx(
						"pointer-events-none inline-block transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
						sizeClasses[size].thumb,
						value ? "" : "",
						disabled ? "bg-gray-400" : "",
					)}
				/>
			</Switch>
		</div>
	)
}

export const Input = {
	Group: InputGroupWrapper,
	Label: InputGroupLabel,
	Input: InputGroupInput,
	TextArea: inputGroupTextArea,
	Select: InputGroupSelect,
	SelectOption: InputGroupSelectOption,
	CheckboxGroup: InputCheckboxGroup,
	Helper: InputGroupHelperText,
	Toggle: InputGroupToggle,
}
