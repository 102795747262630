import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react"
import {
	EllipsisVerticalIcon,
	FolderArrowDownIcon,
	LinkIcon,
	PencilSquareIcon,
	TrashIcon,
} from "@heroicons/react/24/outline"
import assertNever from "assert-never"
import clsx from "clsx"
import { Fragment } from "react"
import { toast } from "react-toastify"

import { baseToastConfig } from "../../../../../config"
import type { CallHighlight } from "../../../../../core/domain/Library.entity"
import type { CoachingSession } from "../../../../../core/infra/gateways/http.library.gateway/getCoachingSessionsResponse.schema"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { TrackingDiv } from "../../../../components/design-system/TrackingDiv.component"
import { useLanguage } from "../../../../contexts/language.context"
import { DEMO_HIGHLIGHT_ID } from "../../../../utils/onboardingDemo/demoLibrary"
import { DateFormatter, DateProvider } from "../../../../utils/time"
import type { CallHighlightActions } from "../types"
import type { CurrentHighlight } from "./CoachingSessionsView.component"
import {
	HighlightCoachingSessionBadge,
	type HighlightCoachingSessionBadgeProps,
} from "./components/HighlightCoachingSessionBadge"
import { HighlightReactionButtons } from "./components/HighlightReactionButtons.component"

export type CurrentLibraryFolderHighlightsGridProps = {
	highlights: CallHighlight[]
	currentHighlightIndex: number
	setCurrentHighlightId: (id: string) => void
	highlightActions: CallHighlightActions
}

export function CurrentLibraryFolderHighlightsGrid({
	highlights,
	currentHighlightIndex,
	setCurrentHighlightId,
	highlightActions,
}: CurrentLibraryFolderHighlightsGridProps) {
	return (
		<div className="grid grid-cols-1 gap-4">
			{highlights.map((highlight, i) => {
				const isSelected = i === currentHighlightIndex
				return (
					<CallHighlightCard
						key={highlight.id}
						onClick={() => setCurrentHighlightId(highlight.id)}
						contents={{
							highlight,
							isSelected,
							highlightActions,
						}}
					/>
				)
			})}
		</div>
	)
}

type CoachingSessionsGridProps = {
	coachingSessions: CoachingSession[]
	currentHighlight: CurrentHighlight
	setCurrentHighlight: (highlightRef: CurrentHighlight) => void
}

export function CoachingSessionsGrid({
	coachingSessions,
	currentHighlight,
	setCurrentHighlight,
}: CoachingSessionsGridProps) {
	const { t, language } = useLanguage()
	const dateFormatter = new DateFormatter(new DateProvider(), t)

	return (
		<div className="grid grid-cols-1">
			{coachingSessions.map((session, i) => {
				return (
					<div key={i} className="flex flex-col gap-4">
						<span key={`title{i}`} className="text-normal text-gray-900 font-medium">
							{dateFormatter.formatDate(session.weekStart, language, {
								year: "numeric",
								month: "short",
								day: "2-digit",
								weekday: "short",
							})}
						</span>
						<div className="flex flex-col gap-4">
							{session.items.map((item, j) => {
								switch (item.type) {
									case "well-played":
										const isSelected =
											i === currentHighlight.sessionIndex && j === currentHighlight.itemIndex

										return (
											<CallHighlightCard
												key={item.callHighlight.id}
												contents={{
													title: item.themeName,
													badge: "top-moment",
													isSelected,
													highlight: item.callHighlight,
												}}
												onClick={() =>
													setCurrentHighlight({
														sessionIndex: i,
														itemIndex: j,
														highlightIndex: 0,
													})
												}
												seen={Boolean(item.seenAt)}
											/>
										)

									case "improvement":
										return (
											<DoubleCallHighlightCard
												key={item.callHighlight.id}
												contents={[
													{
														title: item.themeName,
														badge: "to-improve",
														isSelected:
															i === currentHighlight.sessionIndex &&
															j === currentHighlight.itemIndex &&
															0 === currentHighlight.highlightIndex,
														highlight: item.callHighlight,
													},
													{
														title: undefined,
														badge: "best-practice",
														isSelected:
															i === currentHighlight.sessionIndex &&
															j === currentHighlight.itemIndex &&
															1 === currentHighlight.highlightIndex,
														highlight: item.bestPractice,
													},
												]}
												onClick={[
													() =>
														setCurrentHighlight({
															sessionIndex: i,
															itemIndex: j,
															highlightIndex: 0,
														}),
													() =>
														setCurrentHighlight({
															sessionIndex: i,
															itemIndex: j,
															highlightIndex: 1,
														}),
												]}
												seen={Boolean(item.seenAt)}
											/>
										)

									default:
										assertNever(item)
								}
							})}
						</div>
					</div>
				)
			})}
		</div>
	)
}

type CallHighlightCardProps = {
	onClick: () => void
	contents: CallHighlightCardContents
	seen?: boolean
}

export function CallHighlightCard({ onClick, contents, seen }: CallHighlightCardProps) {
	return (
		<TrackingDiv
			key={contents.highlight.id}
			className={clsx(
				"group relative flex items-center space-x-3 rounded-lg border border-gray-200 bg-white px-6 py-4",
				contents.isSelected && "!bg-indigo-50 !border-indigo-300",
				!contents.isSelected && seen && "opacity-60",
			)}
			eventName="Highlight clicked in grid"
			onClick={onClick}
		>
			<CallHighlightCardContents contents={contents} />
			<CallHighlightCardActions contents={contents} />
		</TrackingDiv>
	)
}

type DoubleCallHighlightCardProps = {
	onClick: [() => void, () => void]
	contents: [CallHighlightCardContents, CallHighlightCardContents]
	seen?: boolean
}

function DoubleCallHighlightCard({ onClick, contents, seen }: DoubleCallHighlightCardProps) {
	return (
		<>
			<TrackingButton
				key={contents[0].highlight.id}
				className={clsx(
					"group relative flex items-center space-x-3 rounded-t-lg border-x border-t border-gray-200 bg-white px-6 pt-4 pb-6",
					contents[0].isSelected && "!bg-indigo-50 !border-indigo-300",
					!contents[0].isSelected && seen && "opacity-60",
				)}
				eventName="Highlight clicked in grid"
				onClick={onClick[0]}
			>
				<CallHighlightCardContents contents={contents[0]} />
				<CallHighlightCardActions contents={contents[0]} />
			</TrackingButton>
			<TrackingButton
				key={contents[1].highlight.id}
				className={clsx(
					"group relative flex items-center space-x-3 rounded-b-lg border-x border-b border-gray-200 bg-white px-6 pt-2 pb-4",
					contents[1].isSelected && "!bg-indigo-50 !border-indigo-300",
					!contents[1].isSelected && seen && "opacity-60",
					"-mt-5",
					"before:absolute before:top-0 before:left-[12.5%] before:w-[75%] before:h-0 before:border-t before:border-gray-200",
				)}
				eventName="Highlight clicked in grid"
				onClick={onClick[1]}
			>
				<CallHighlightCardContents contents={contents[1]} />
				<CallHighlightCardActions contents={contents[1]} />
			</TrackingButton>
		</>
	)
}

type CallHighlightCardContents = {
	title?: string
	highlight: CallHighlight
	isSelected: boolean
	highlightActions?: CallHighlightActions
	badge?: HighlightCoachingSessionBadgeProps["type"]
}

export function CallHighlightCardContents({
	contents: { title, isSelected, highlight },
}: {
	contents: { title?: string; highlight: CallHighlight; isSelected: boolean }
}) {
	const { t, language } = useLanguage()
	const dateFormatter = new DateFormatter(new DateProvider(), t)

	return (
		<>
			{/* <div className="flex-shrink-0">
							<BookmarkIcon className="h-6 w-6 text-indigo-700 group-hover:text-indigo-500" />
						</div> */}
			<div className="min-w-0 flex-1 text-left">
				{title && <span>{title}</span>}
				<p
					className={clsx(
						"border-l-[3px] bg-gray-50  mt-2 mb-2 text-gray-900 py-0.5 px-2",
						isSelected ? "bg-indigo-50 border-navy-500" : "border-gray-700",
					)}
				>
					{highlight.comment}
				</p>
				<p className="text-sm text-gray-500">
					<span className="text-normal text-gray-900 font-medium">
						{highlight.creatorFullName ?? t("Deleted user")}
					</span>{" "}
					{t("created this highlight on")}{" "}
					<span className="text-normal text-gray-900 font-medium">
						{dateFormatter.formatDate(highlight.creationDate, language, {
							year: "numeric",
							month: "short",
							day: "2-digit",
							weekday: "short",
						})}
					</span>
				</p>
				<div className="flex items-center gap-x-1.5 text-sm text-gray-400 mt-1">
					<p className="truncate text-sm text-gray-500">
						<span className="text-normal text-gray-900 font-medium">
							{dateFormatter.formatFixedDuration(
								(highlight.endsAtMs - highlight.startsAtMs) / 1000,
								language,
							)}
						</span>{" "}
						from the call{" "}
						<span className="text-normal text-gray-900 font-medium">{highlight.callName}</span>
					</p>
				</div>
			</div>
		</>
	)
}

type CallHighlightCardActionsProps = {
	contents: CallHighlightCardContents
}

function CallHighlightCardActions({ contents: { highlight, highlightActions, badge } }: CallHighlightCardActionsProps) {
	if (highlight.id === DEMO_HIGHLIGHT_ID) return null

	return (
		<div className="flex-shrink-0 mb-auto mt-2 flex-col items-end flex justify-between h-full">
			{/* TODO: fix the navigation post deletion (button is within an anchor) */}
			{highlightActions && (
				<CallHighlightCardMenu
					onCopyLink={() => highlightActions.onHighlightCopyLink(highlight)}
					onEditComment={() => highlightActions.onHighlightEditComment(highlight)}
					onMoveToFolder={() => highlightActions.onHighlightEditParentFolder(highlight)}
					onDelete={() => highlightActions.onHighlightDelete(highlight)}
				/>
			)}
			<HighlightReactionButtons reactions={highlight.reactions} highlightId={highlight.id} />
			{badge && <HighlightCoachingSessionBadge type={badge} />}
		</div>
	)
}

type CallHighlightCardMenuProps = {
	onCopyLink: () => void
	onEditComment: () => void
	onMoveToFolder: () => void
	onDelete: () => void
}

function CallHighlightCardMenu({ onCopyLink, onEditComment, onMoveToFolder, onDelete }: CallHighlightCardMenuProps) {
	const { t } = useLanguage()

	const handleCopy = () => {
		onCopyLink()
		toast.success(t("Copied!"), baseToastConfig)
	}

	return (
		<Menu as="div" className="relative inline-block text-left">
			<div>
				<MenuButton className="relative -m-2 flex items-center rounded-full p-1 text-gray-500 hover:text-gray-600 hover:bg-gray-200  mr-2">
					<span className="absolute -inset-1" />
					<span className="sr-only">Open options</span>
					<EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
				</MenuButton>
			</div>
			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<MenuItems className="absolute right-0 z-10 mt-2 w-44 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<div className="py-1">
						{/* copy link to highlight */}
						<MenuItem>
							{({ active }) => (
								<TrackingButton
									type="button"
									className={clsx(
										active ? "bg-gray-100 text-gray-900" : "text-gray-700",
										"flex px-4 py-2 text-sm w-full text-left",
									)}
									onClick={handleCopy}
									eventName="Copy link to highlight"
								>
									<LinkIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
									<span>{t("Copy link")}</span>
								</TrackingButton>
							)}
						</MenuItem>
						<MenuItem>
							{({ active }) => (
								<TrackingButton
									type="button"
									className={clsx(
										active ? "bg-gray-100 text-gray-900" : "text-gray-700",
										"flex px-4 py-2 text-sm w-full text-left",
									)}
									onClick={() => onEditComment()}
									eventName="Open call highlight comment edition modal"
								>
									<PencilSquareIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
									<span>{t("Edit comment")}</span>
								</TrackingButton>
							)}
						</MenuItem>
						<MenuItem>
							{({ active }) => (
								<TrackingButton
									type="button"
									className={clsx(
										active ? "bg-gray-100 text-gray-900" : "text-gray-700",
										"flex px-4 py-2 text-sm w-full text-left",
									)}
									onClick={() => onMoveToFolder()}
									eventName="Open call highlight parent edition modal"
								>
									<FolderArrowDownIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
									<span>{t("Move highlight")}</span>
								</TrackingButton>
							)}
						</MenuItem>
						<MenuItem>
							{({ active }) => (
								<TrackingButton
									type="button"
									className={clsx(
										active ? "bg-gray-100 text-red-600" : "text-red-500",
										"flex px-4 py-2 text-sm w-full",
									)}
									onClick={onDelete}
									eventName="Delete call highlight"
								>
									<TrashIcon className="mr-3 h-5 w-5 text-red-400" aria-hidden="true" />
									<span>{t("Delete…")}</span>
								</TrackingButton>
							)}
						</MenuItem>
					</div>
				</MenuItems>
			</Transition>
		</Menu>
	)
}
