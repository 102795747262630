import React, { useEffect, useRef } from "react"

import type { Call } from "../../../../core/domain/Call.entity"
import type { ChatSession } from "../../../../core/domain/ChatSession.entity"
import { useDependencies } from "../../../contexts/dependencies.context"
import { ChatWidget } from "./ChatWidget.component"

type CallChatWidgetProps = {
	call: Call
}

export function CallChatWidget({ call }: CallChatWidgetProps) {
	const [chatSession, setChatSession] = React.useState<ChatSession | null>(null)
	const isSessionInitialized = useRef(false)
	const { chatSessionGateway } = useDependencies()
	const chatSessionId = chatSession?.properties.id

	useEffect(() => {
		async function tryCreateChatSession() {
			try {
				const newSession = await chatSessionGateway.createCallSession(call.props.id)
				setChatSession(newSession)
			} catch (e) {
				console.error("Failed to create chat session", e)
			}
		}

		async function getOrCreateChatSession() {
			if (isSessionInitialized.current) return
			isSessionInitialized.current = true

			try {
				const session = await chatSessionGateway.getByCallId(call.props.id)
				setChatSession(session)
			} catch (e) {
				console.warn("No chat session found for call id", call.props.id, "creating", e)
				await tryCreateChatSession()
			}
		}

		getOrCreateChatSession()
	}, [call.props.id, chatSessionGateway, chatSessionId])

	return <ChatWidget context="call" chatSession={chatSession} onChatSessionChange={setChatSession} call={call} />
}
