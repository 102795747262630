import {
	type GetAllReportsFilter,
	getAllReportsResponseSchema,
	type IObjectionsGateway,
	type ObjectionReport,
} from "../../application/gateways/objections.gateway"

const now = new Date()
const aMonthAgo = new Date(now)
aMonthAgo.setMonth(now.getMonth() - 1)

const fourMonthsAgo = new Date(now)
fourMonthsAgo.setMonth(now.getMonth() - 4)

const _mockedReports: ObjectionReport[] = [
	{
		id: "1",
		createdAt: now,
		callsCount: 0,
		sections: [
			{
				title: "Section 1",
				description: "Description 1",
				responseAnalysis: "Response Analysis 1",
				recommendations: ["Recommendation 1"],
				riskLevel: "low",
				badges: ["new"],
			},
		],
	},
	{
		id: "2",
		createdAt: aMonthAgo,
		callsCount: 0,
		sections: [
			{
				title: "Section 2",
				description: "Description 2",
				responseAnalysis: "Response Analysis 2",
				recommendations: ["Recommendation 2"],
				riskLevel: "medium",
				badges: ["new"],
			},
		],
	},
	{
		id: "3",
		createdAt: fourMonthsAgo,
		callsCount: 0,
		sections: [
			{
				title: "Section 3",
				description: "Description 3",
				responseAnalysis: "Response Analysis 3",
				recommendations: ["Recommendation 3"],
				riskLevel: "high",
				badges: ["new"],
			},
		],
	},
]

export class HttpObjectionsGateway implements IObjectionsGateway {
	constructor(private readonly baseApiUrl: string) {}

	public async getAllReports(filter?: GetAllReportsFilter) {
		const params = new URLSearchParams()
		if (filter?.teamId) {
			params.append("teamId", filter.teamId)
		}
		const url = `${this.baseApiUrl}/objections/reports?${params}`

		const res = await fetch(url, {
			method: "GET",
			credentials: "include",
		})

		const json = await res.json()
		return getAllReportsResponseSchema.parse(json)
	}
}
