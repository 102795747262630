import z from "zod"

export const getTaskResultResponse = z.union([
	z.object({
		status: z.literal("pending"),
	}),
	z.object({
		status: z.literal("completed"),
		result: z.any(),
	}),
	z.object({
		status: z.literal("failed"),
		error: z.string(),
	}),
])

export type GetTaskResultResponse<T> =
	| {
			status: "pending"
	  }
	| {
			status: "completed"
			result: T
	  }
	| {
			status: "failed"
			error: string
	  }

export type ITasksGateway = {
	getTaskResult: <T>(taskId: string) => Promise<GetTaskResultResponse<T>>
}
