import React from "react"

import { SpinnerIcon } from "../../../components/design-system/SpinnerIcon.component"
import { type TranslationKey, useLanguage } from "../../../contexts/language.context"

const loadingSteps: TranslationKey[] = [
	"Fetching attendees...",
	"Scraping domains...",
	"Scraping LinkedIn...",
	"Processing insights...",
	"Finalising brief...",
]

export function BriefLoading() {
	const [currentStep, setCurrentStep] = React.useState(0)
	const { t } = useLanguage()

	React.useEffect(() => {
		const interval = setInterval(() => {
			if (currentStep === loadingSteps.length - 1) {
				clearInterval(interval)
				return
			}

			setCurrentStep((prev) => (prev + 1) % loadingSteps.length)
		}, 5000)

		return () => clearInterval(interval)
	}, [currentStep])

	return (
		<>
			<div className="p-6 flex flex-col items-center space-y-4">
				<SpinnerIcon className="h-8 w-8 animate-spin text-indigo-500" />
				<h2 className="text-xl font-semibold text-gray-800">{t("Your meeting brief is being generated")}</h2>
				<p className="text-sm text-gray-600 text-center">{t(loadingSteps[currentStep])}</p>
				<p className="text-sm text-gray-600 text-center">
					{t(
						"We're working on your meeting brief. It will be ready shortly. Feel free to grab a coffee while you wait!",
					)}
				</p>
			</div>
		</>
	)
}
