import React from "react"
import { Helmet } from "react-helmet"
import { toast } from "react-toastify"
import { useMount } from "react-use"

import {
	type BriefCompletedTaskResult,
	BriefRequestError,
	type GenerateBriefParams,
} from "../../../core/application/gateways/public-brief.gateway"
import { useAnalytics } from "../../contexts/analytics.context"
import { useDependencies } from "../../contexts/dependencies.context"
import { PublicBriefForm } from "./components/BriefForm.component"
import { BriefGeneratedError } from "./components/BriefGeneratedError.component"
import { BriefGeneratedState } from "./components/BriefGeneratedSuccess.component"
import { BriefLoading } from "./components/BriefLoading.component"

type FormState =
	| {
			status: "idle"
	  }
	| {
			status: "error"
			error: string
			hasReachedRateLimit?: boolean
	  }
	| {
			status: "submitting"
			taskId: string
			email: string
	  }
	| {
			status: "done"
			email: string
			claimUrl: string | null
	  }

export function PublicBriefFormPage() {
	const { trackPageView, setupEventTracking } = useAnalytics()
	const { publicBriefGateway, tasksGateway } = useDependencies()
	const [formState, setFormState] = React.useState<FormState>({
		status: "idle",
	})
	const [isSubmitting, setIsSubmitting] = React.useState(false)

	React.useEffect(() => {
		const interval = setInterval(async () => {
			if (formState.status !== "submitting") {
				clearInterval(interval)
				return
			}

			try {
				const result = await tasksGateway.getTaskResult<BriefCompletedTaskResult>(formState.taskId)
				console.log("Got brief status", result)
				if (result.status === "completed") {
					setFormState({ status: "done", email: formState.email, claimUrl: result.result.claimUrl ?? null })
					clearInterval(interval)
				} else if (result.status === "failed") {
					setFormState({ status: "error", error: result.error })
				}
			} catch (e) {
				console.error("Failed to get brief status", e)
				setFormState({ status: "error", error: "Failed to get brief status" })
				clearInterval(interval)
			}
		}, 3000)

		return () => {
			clearInterval(interval)
		}
	}, [formState, publicBriefGateway, tasksGateway])

	const handleSubmit = React.useCallback(
		async (payload: GenerateBriefParams) => {
			try {
				setupEventTracking({
					eventName: "Public brief: form submitted",
					eventProperties: {
						...payload,
					},
				}).trackEvent()
				setIsSubmitting(true)
				const { id } = await publicBriefGateway.generateBrief(payload)
				setFormState({ status: "submitting", taskId: id, email: payload.email })
			} catch (error) {
				if (error instanceof BriefRequestError) {
					setFormState({ status: "error", error: error.message, hasReachedRateLimit: error.code === 429 })
					return
				}

				console.error(error)
				toast.error("Failed to generate insights. Please try again.")
			} finally {
				setIsSubmitting(false)
			}
		},
		[publicBriefGateway, setupEventTracking],
	)

	const handleRetry = React.useCallback(() => {
		setFormState({ status: "idle" })
	}, [])

	useMount(() => {
		trackPageView("public brief form", window.location.pathname)
	})

	return (
		<>
			<Helmet>
				<meta name="robots" content="noindex,nofollow" />
			</Helmet>
			<div className="flex flex-col min-h-screen px-4 py-2 bg-[#f7dcbb] items-center justify-center">
				<div className="border border-gray-200 bg-white rounded-lg p-4 shadow-lg">
					<div className="flex items-center justify-center">
						{formState.status === "idle" && (
							<PublicBriefForm isSubmitting={isSubmitting} onSubmit={handleSubmit} />
						)}
						{formState.status === "submitting" && <BriefLoading />}
						{formState.status === "done" && (
							<BriefGeneratedState claimUrl={formState.claimUrl} email={formState.email} />
						)}
						{formState.status === "error" && (
							<BriefGeneratedError
								onRetry={handleRetry}
								error={formState.error}
								hasReachedRateLimit={formState.hasReachedRateLimit}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	)
}
