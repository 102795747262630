import { SparklesIcon } from "@heroicons/react/20/solid"
import React, { useEffect } from "react"
import { useClickAway } from "react-use"

import type { Call } from "../../../../core/domain/Call.entity"
import type { ChatSessionHistoryEntry } from "../../../../core/domain/ChatSession.entity"
import type { ChatSession } from "../../../../core/domain/ChatSession.entity"
import { useAnalytics } from "../../../contexts/analytics.context"
import { useDependencies } from "../../../contexts/dependencies.context"
import { useLanguage } from "../../../contexts/language.context"
import { useSupportWidget } from "../../../SupportWidget"
import { documentElementToMarkdown } from "../../../utils/documentElementParsing"
import { sleep } from "../../../utils/time"
import { Badge } from "../../design-system/Badge.component"
import { TrackingButton } from "../../design-system/TrackingButton.component"
import { ChatWindow, type ChatWindowMessage } from "../ChatWindow.component"

type BaseChatWidgetProps = {
	chatSession: ChatSession | null
	onChatSessionChange: React.Dispatch<React.SetStateAction<ChatSession | null>>
}

type ChatWidgetProps = (
	| {
			context: "call"
			call: Call
	  }
	| {
			context: "workspace"
	  }
) &
	BaseChatWidgetProps

function convertChatSessionHistoryMessageToChatWindowMessage(historyEntry: ChatSessionHistoryEntry): ChatWindowMessage {
	return {
		text: documentElementToMarkdown(historyEntry.content),
		from: historyEntry.from,
		date: new Date(historyEntry.createdAt),
	}
}

export function ChatWidget({ context, chatSession, onChatSessionChange, ...otherProps }: ChatWidgetProps) {
	const [isChatOpen, setIsChatOpen] = React.useState(false)
	const { chatSessionGateway } = useDependencies()
	const { t } = useLanguage()
	const messages = (() => {
		if (!chatSession) {
			return []
		}
		if (chatSession.properties.history.length === 0) {
			return chatSession.properties.helloMessages.map((message) =>
				convertChatSessionHistoryMessageToChatWindowMessage({
					from: "ai",
					createdAt: new Date().toISOString(),
					content: message,
				}),
			)
		}
		return chatSession.properties.history.map(convertChatSessionHistoryMessageToChatWindowMessage)
	})()
	const { isWidgetVisible } = useSupportWidget()

	const chatSessionId = chatSession?.properties.id
	useEffect(() => {
		let isMounted = true

		async function updateSession(): Promise<ChatSessionHistoryEntry | null> {
			if (!chatSessionId) {
				return null
			}

			const newSession = await chatSessionGateway.getById(chatSessionId)
			const lastMessage = newSession.properties.history[newSession.properties.history.length - 1]
			if (!lastMessage) {
				return null
			}

			const isLastMessageFromAi = lastMessage.from === "ai"
			const hasNewMessages = !chatSession.properties.history.some(
				(entry) => entry.createdAt === lastMessage.createdAt,
			)
			if (isLastMessageFromAi && hasNewMessages) {
				onChatSessionChange(newSession)
			}

			return lastMessage
		}

		async function poll(): Promise<void> {
			if (!isMounted) {
				return
			}

			try {
				const lastReceivedMessage = await updateSession()
				const sleepTime = !isChatOpen
					? 10000
					: lastReceivedMessage && lastReceivedMessage.from === "ai"
					? 2500
					: 1000

				await sleep(sleepTime)
				return poll()
			} catch (e) {
				console.error("Failed to update chat session", e)
			}
		}

		poll()
		return () => {
			isMounted = false
		}
	}, [chatSessionId, chatSessionGateway, chatSession, isChatOpen, onChatSessionChange])

	const ref = React.useRef<HTMLDivElement>(null)
	const buttonRef = React.useRef<HTMLButtonElement>(null)
	const containerRef = React.useRef<HTMLDivElement>(null)
	useClickAway(containerRef, (event) => {
		if (buttonRef.current?.contains(event.target as Node)) {
			return
		}
		setIsChatOpen(false)
	})

	React.useEffect(() => {
		if (ref.current) {
			ref.current.scrollTop = ref.current.scrollHeight
		}
	}, [chatSession, isChatOpen])

	const { setupEventTracking } = useAnalytics()

	const handleUserMessage = React.useCallback(
		async (message: string) => {
			if (!chatSession) {
				return
			}

			onChatSessionChange((prev) => {
				if (!prev) {
					return prev
				}

				return prev.withAddedUserMessage(message)
			})

			const sendMessageAnalytics = setupEventTracking({
				eventName: "Chat message sent",
				eventProperties: {
					message,
					callId: context === "call" ? (otherProps as { call: Call }).call.props.id : undefined,
					context,
				},
			})
			sendMessageAnalytics.trackEvent()
			await chatSessionGateway.sendMessage(chatSession, message)
		},
		[chatSession, chatSessionGateway, context, onChatSessionChange, otherProps, setupEventTracking],
	)

	const handleChatButtonClick = React.useCallback(() => {
		setIsChatOpen((prev) => !prev)
	}, [])

	if (!chatSession || isWidgetVisible) {
		return null
	}

	return (
		<>
			<div className="z-[99] fixed bottom-4 right-4">
				<TrackingButton
					className="relative inline-flex items-center justify-center text-sm font-medium disabled:pointer-events-none disabled:opacity-50 border-4 border-blue-500 rounded-full w-16 h-16 bg-navy-500 hover:bg-navy-700 m-0 cursor-pointer p-0 normal-case leading-5 text-gray-900 shadow-lg transition-shadow duration-300 animate-border"
					type="button"
					eventName="Call chat widget clicked"
					onClick={handleChatButtonClick}
					ref={buttonRef}
				>
					<SparklesIcon className="h-6 w-6 text-yellow-300" />
					<Badge label={t("Beta")} variant="info" className="absolute -top-4 left-6" />
				</TrackingButton>
			</div>
			{isChatOpen && (
				<div ref={containerRef}>
					<ChatWindow messages={messages} onUserMessage={handleUserMessage} ref={ref} asMarkdown={true} />
				</div>
			)}
		</>
	)
}
