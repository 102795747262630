import type { BaseCall } from "../../core/domain/BaseCall.entity"
import { Call } from "../../core/domain/Call.entity"
import { useSession } from "../contexts/authentication.context"
import { useWorkspaceUsers } from "../contexts/workspace-users.context"

export type CallFeaturesVisibility = {
	scoring: boolean
	brief: boolean
	objections: boolean
	coaching: boolean
	leadKnowledge: boolean
}

export function useCallFeaturesVisibility(call: BaseCall): CallFeaturesVisibility {
	const isAssignedUserIntesciaCSM = useAssignedUserIsIntesciaCSM(call)
	const { user, workspace } = useSession()

	const hasFrameworkScoring =
		call instanceof Call && call.props.frameworkScorings && call.props.frameworkScorings.length > 0

	return {
		scoring: Boolean(
			(workspace?.props.featureFlags.callScoringAnalysis || hasFrameworkScoring) &&
				call instanceof Call &&
				user?.canAccessReviewOf(call) &&
				!call.isDemoCall() &&
				!isAssignedUserIntesciaCSM,
		),
		brief: call instanceof Call && !!call.props.briefing,
		objections: !isAssignedUserIntesciaCSM,
		coaching: !isAssignedUserIntesciaCSM,
		leadKnowledge: !isAssignedUserIntesciaCSM,
	}
}

/**
 * This hook is extremely hacky and its code shouldn't be propagated to other parts of the codebase.
 * This is a client-side check used to temporarily offer a different experience to Intescia CSMs.
 */
function useAssignedUserIsIntesciaCSM(call: BaseCall) {
	const { user } = useSession()

	const { workspaceUsers, isLoading } = useWorkspaceUsers()

	if (!(call instanceof Call)) {
		return false
	}

	if (user?.isWorkspaceManager()) {
		if (isLoading || !workspaceUsers) {
			// assumes the assigned user is an Intescia CSM while loading (to avoid blinking)
			return true
		}
		const assignedUser = workspaceUsers.find((user) => user.id === call.props.assignedUserId)
		return Boolean(assignedUser?.isIntesciaCSM())
	}

	const isCallAssignedToCurrentUser = user?.id === call.props.assignedUserId
	return isCallAssignedToCurrentUser ? Boolean(user?.isIntesciaCSM()) : false
}
